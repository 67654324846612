<form *ngIf="form && schema" [formGroup]="form" (ngSubmit)="submitForm()">
   <div *ngFor="let ctl of form.controls | keyvalue: keyInsertionOrder" class="field-container">
      <ng-container *ngTemplateOutlet="formFields; context: {
         ctlName: ctl.key,
         schemaItem: schema[ctl.key],
         nestedGroupCtls: ctl.value.controls
      };"></ng-container>

      <ng-template #formFields let-ctlName="ctlName" let-nestedGroupCtls="nestedGroupCtls" let-schemaItem="schemaItem">
         <ng-container [ngSwitch]="getFieldType(schemaItem)">

            <ng-container *ngSwitchCase="'checkbox'" >
               <mat-checkbox  [formControl]="form.get(ctlName)">
                  {{schemaItem.inlineTitle || schemaItem.label}}
               </mat-checkbox>
            </ng-container>

            <ng-container *ngSwitchCase="'autocomplete'">
               <ng-container *ngIf="filteredFormOptions$ && (filteredFormOptions$[ctlName] | async) as filteredOptions">
                  <bb-autocomplete-item *ngIf="filteredOptions"
                     [groupOptions]="filteredOptions.options"
                     [validators]="schemaItem.validators"
                     [asyncValidators]="schemaItem.asyncValidators"
                     [label]="schemaItem.label"
                     [inValue]="form.get(ctlName).value"
                     (inValueChange)="formValueUpdate($event, ctlName)"
                     [required]="true"
                     [disabled]="schemaItem?.readonly"
                  ></bb-autocomplete-item>
                  <mat-hint
                     *ngIf="filteredOptions && filteredOptions.asyncLoading"
                     class="options-loading"
                  >
                     <bb-loading></bb-loading>
                  </mat-hint>
               </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'typeahead-dir'">
               <ng-container *ngIf="filteredFormOptions$ && (filteredFormOptions$[ctlName] | async) as filteredOptions">
                  <bb-autocomplete-item *ngIf="filteredOptions"
                     [groupOptions]="filteredOptions.options"
                     [validators]="schemaItem.validators"
                     [asyncValidators]="schemaItem.asyncValidators"
                     [label]="schemaItem.label"
                     [inValue]="form.get(ctlName).value"
                     (inValueChange)="formValueUpdate($event, ctlName)"
                     (selectionChanged)="formValueUpdate($event, ctlName)"
                     [required]="schemaItem.required"
                     [disabled]="schemaItem?.readonly"
                  ></bb-autocomplete-item>
                  <mat-hint
                     *ngIf="filteredOptions && filteredOptions.asyncLoading"
                     class="options-loading"
                  >
                     <bb-loading></bb-loading>
                  </mat-hint>
               </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'toggle'">
               <mat-label class="mat-slide-toggle-label">
                  {{schemaItem.label}}
               </mat-label>
               <mat-slide-toggle [formControl]="form.get(ctlName)" color="primary" [disabled]="schemaItem?.readonly">
                  {{form.get(ctlName).value ? 'Yes' : 'No'}}
               </mat-slide-toggle>
            </ng-container>

            <mat-form-field *ngSwitchCase="'textarea'">
               <mat-label>{{schemaItem.label}}</mat-label>
               <textarea matInput [required]="schemaItem.required" [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly"></textarea>
               <mat-error *ngIf="form">
                  {{getErrorMessage(ctlName, schemaItem)}}
               </mat-error>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'select'">
               <mat-label>{{schemaItem.label}}</mat-label>
               <mat-select [formControl]="form.get(ctlName)" placeholder="--- Select an option ---" [disabled]="schemaItem?.readonly">
                  <mat-option *ngFor="let option of schemaItem.valueLabels" [value]="option">
                     {{option.label}}
                  </mat-option>
               </mat-select>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'select-combo'">
               <mat-label>{{schemaItem.label}}</mat-label>
               <mat-select [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly">
                  <mat-select-trigger>
                     {{form.get(ctlName).value}}
                  </mat-select-trigger>
                  <ng-container *ngIf="filteredFormOptions$ && (filteredFormOptions$[ctlName] | async) as filteredOptions">
                     <ng-container *ngIf="filteredOptions">
                        <ng-container *ngFor="let group of filteredOptions.options">
                           <ng-container *ngIf="group.groupName; else options">
                              <mat-optgroup [label]="group.groupName">
                                 <ng-container *ngTemplateOutlet="options"></ng-container>
                              </mat-optgroup>
                           </ng-container>
                           <ng-template #options>
                              <ng-container *ngIf="group.children.length; else noResults">
                                 <mat-option *ngFor="let child of group.children" [value]="child.value" [disabled]="child?.disabled">
                                    <span class="spaced-options">
                                       <span>
                                          <mat-hint *ngIf="child.preLabel">{{child.preLabel}}</mat-hint>
                                          {{child.value}}
                                       </span>
                                       <span *ngIf="child.additionalInfo">{{child.additionalInfo}}</span>
                                       <span *ngIf="child.label"><mat-hint>({{child.label}})</mat-hint></span>
                                    </span>
                                 </mat-option>
                              </ng-container>
                              <ng-template #noResults>
                                 <mat-option disabled>
                                    <span class="mat-option-text">
                                       No results found
                                    </span>
                                 </mat-option>
                              </ng-template>
                           </ng-template>
                        </ng-container>
                     </ng-container>
                  </ng-container>
               </mat-select>
            </mat-form-field>

            <div *ngSwitchCase="'button'" class="form-button">
               <bb-form-button  [btnLabel]="schemaItem.label"  [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly"></bb-form-button>
            </div>

            <mat-accordion *ngSwitchCase="'group'">
               <mat-expansion-panel
                  [expanded]="schemaItem?.hideable == false || isExpansionPanelOpen(ctlName) ? true : false"
                  (opened)="expansionPanelOpened(ctlName, true)"
                  (closed)="expansionPanelOpened(ctlName, false)"
               >
                  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
                     <mat-panel-title>
                        {{schemaItem.label}}
                     </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-container *ngFor="let nestedCtl of nestedGroupCtls | keyvalue: keyInsertionOrder">
                     <ng-container
                        *ngTemplateOutlet="formFields; context: {
                           ctlName: ctlName + '.' + nestedCtl.key,
                           schemaItem: schemaItem.groupFields[nestedCtl.key]
                        }"
                     >
                     </ng-container>
                  </ng-container>
               </mat-expansion-panel>
            </mat-accordion>

            <mat-form-field *ngSwitchCase="'number'">
               <mat-label>{{schemaItem.label}}</mat-label>
               <input matInput type="number" [required]="schemaItem.required" [formControl]="form.get(ctlName)" [disabled]="schemaItem?.readonly"/>
               <mat-error *ngIf="form">
                  {{getErrorMessage(ctlName, schemaItem)}}
               </mat-error>
            </mat-form-field>

            <!--
               Dynamically setting input [type]="variable" does not work https://github.com/angular/angular/issues/13243
               so the type attributes should be statically set (as is done above for type="number")
             -->
            <mat-form-field *ngSwitchDefault>
               <mat-label>{{schemaItem.label}}</mat-label>
               <!--
                  [required] is set as an attribute purely to show the asterisk. With Angular13,
                  setting Validators.required on the FormControl is enough to show the asterisk,
                  so this attribute should be removed from all mat-form-fields.
                  https://github.com/angular/components/pull/23362
               -->
               <input
                  matInput
                  [type]="getFieldType(schemaItem)"
                  [required]="schemaItem.required"
                  [formControl]="form.get(ctlName)"
                  [disabled]="schemaItem?.readonly"
               />
               <mat-error *ngIf="form">
                  {{getErrorMessage(ctlName, schemaItem)}}
               </mat-error>
            </mat-form-field>
         </ng-container>

         <p
            *ngIf="schemaItem.maxLength"
            class="character-counter"
            [ngClass]="{invalid: hasError(ctlName, 'maxlength'), touched: form.get(ctlName).touched}"
         >
            {{characterCounter(ctlName)}}
         </p>
      </ng-template>
   </div>

   <mat-toolbar *ngIf="submitConfig && this?.form.dirty" class="submit-toolbar">
      <button mat-raised-button type="submit" color="primary" [disabled]="submitting || form.invalid">
         {{submitting ? submitConfig.inProgressLabel : submitConfig.submitLabel}}
      </button>
   </mat-toolbar>
</form>
