import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccountDto } from '../../../shared/api/accounts/account.interface';
import { EditorDataApi } from '../../../shared/api/editor-data-api.factory';
import { Site } from '../../../shared/types/site.interface';
import { Me } from '../../../store/users';
import { SubmitConfig } from '../../../shared/dynamic-form';
import { SelectableOption } from '../../../shared/dynamic-form/form.interface';
import { FormHelperService } from '../../../shared/dynamic-form/ng/form-helper.service';
import { Schema, SchemaItem } from '../../../shared/dynamic-form/schema.interface';
import { BillingGroup, EntityBase, LaunchModeDto, LaunchModeName } from '../../../shared/types';
import { NavigationService } from '../../../shared/utils/navigation/navigation.service';
import { PermissionsChecker } from '../../../shared/utils/permissions-checker';
import { AccountsApiService } from '../../../shared/api/accounts/accounts.api.service';
import { Observable, Subject, combineLatest, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { SitesApiService } from '../../../shared/services/sites.api.service';
import { AlertService } from '../../../shared/services/alert.service';
import { UsersApiService } from '../../../shared/api/users.api.service';
import { PageService } from '../../../shared/services/page.service';


interface OptionData {
   sites?: Site[];
   editorVersions: EntityBase[];
   editorLaunchModes: LaunchModeDto[];
   editorSkins: EntityBase[];
}

@Component({
   selector: 'bb-account-data-form',
   templateUrl: './account-data-form.component.html',
   styleUrls: ['./account-data-form.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class AccountDataFormComponent implements OnInit, OnDestroy {
   public form: FormGroup;
   public modelReady = false;
   public schema: Schema<Partial<AccountDto>> = null;
   public submitConfig: SubmitConfig = {
      submitLabel: 'Save',
      inProgressLabel: 'Saving',
      formName: 'Account Data',
      onSubmit: this.submitForm.bind(this),
   };
   public billingGroups = ["billable", "non-billable"];
   ngDestroy$ = new Subject();

   private isAdmin: boolean;
   private canChangeReseller: boolean;
   private canChangeSite: boolean;
   private account: AccountDto;
   private sites: Site[];
   private page: Page;

   constructor(
      private alertService: AlertService,
      private pageService: PageService,
      @Inject('EditorDataApi') private editorDataApi: EditorDataApi,
      @Inject('NavigationService') private navigationService: NavigationService,
      private usersApiService: UsersApiService,
      private sitesApiService: SitesApiService,
      private accountsApiService: AccountsApiService,
      private formHelperService: FormHelperService,
   ) {
      this.page = this.pageService.page;
   }

   ngOnInit(): void {
      const promises: IPromise<any>[] = [];
      const observables$: Observable<any>[] = [];

      this.usersApiService.fetchMe().pipe(
         catchError((error) =>  {
            this.showError('user', error);
            return throwError(error);
         }),
      )
      .subscribe((me: Me) => {
         this.isAdmin = PermissionsChecker.isAdmin(me);
         this.canChangeReseller = PermissionsChecker.isResellerManager(me, this.page.sitegroupId) || this.isAdmin;
         this.canChangeSite = PermissionsChecker.isSiteManager(me, this.page.siteId) || this.canChangeReseller;

         observables$.push(this.accountsApiService.fetch(this.page.accountId));

         if (this.isAdmin) {
            promises.push(this.editorDataApi.fetchVersions().catch(e => this.showError('editor versions', e)));
            promises.push(this.editorDataApi.fetchLaunchModes().catch(e => this.showError('editor launch modes', e)));
            promises.push(this.editorDataApi.fetchSkins().catch(e => this.showError('editor skins', e)));
            observables$.push(this.sitesApiService.fetchAll());
         }

         combineLatest(observables$)
            .pipe(
               takeUntil(this.ngDestroy$),
               catchError(async (error) => this.showError('sites or account', error))
            )
            .subscribe(
               (results: any[]) => {
                  this.account = results[0];
                  this.sites = ( results.length > 1 ) ? results[1] : [];
                  Promise.all(promises)
                  .then(([editorVersions, editorLaunchModes, editorSkins]:
                        [EntityBase[], LaunchModeDto[], EntityBase[]]) => {

                     this.form = this.initForm({editorVersions, editorLaunchModes, editorSkins});
                     this.modelReady = true;
                  });
               });

      });
   }

   ngOnDestroy(): void {
      this.ngDestroy$.next(true);
      this.ngDestroy$.complete();
   }

   private initForm(optionData: OptionData): FormGroup {
      let disabled: SchemaItem;
      let siteId: SchemaItem;
      let version: SchemaItem;
      let defaultEditMode: SchemaItem;
      let skin: SchemaItem;
      let billingGroup: SchemaItem;
      let notes: SchemaItem;

      const editLimitOptions: SelectableOption[] = [
         { label: 'No limit',                value: null },
         { label: 'No users - lock access',  value: 0 },
         { label: '1 user',                  value: 1 },
         ...Array.from(
            { length: 49 },
            (v, i) => ({ label: `${i + 2} users`, value: i + 2 }),
         ),
      ];

      const fullName: SchemaItem   = { type: 'text', label: 'Production Name (readable format)', maxLength: 100 };
      const department: SchemaItem = { type: 'text', label: 'Department', maxLength: 100 };
      const billingId: SchemaItem  = { type: 'text', label: 'Billing ID', maxLength: 100, readonly: !this.canChangeSite };
      const editLimit: SchemaItem  = { type: 'select', label: 'Concurrent User Limit', valueLabels: editLimitOptions };
      const message: SchemaItem    = { type: 'textarea', label: 'Welcome Message' };

      if (this.isAdmin) {
         const frontendVersionOptions = optionData.editorVersions.map(vsn => ({ value: vsn.id, label: vsn.name }));

         const defaultLaunchMode = optionData.editorLaunchModes
            .filter(mode => mode.default)
            .map(mode => ({value: 'inherit', label: `Default (${mode.label})`}));
         const launchModes = optionData.editorLaunchModes.map(mode => ({ value: mode.name, label: mode.label }));
         const editLaunchModeOptions = defaultLaunchMode.concat([], launchModes);

         const editorSkins = optionData.editorSkins.map(s => ({value: s.id, label: s.name}));
         const skinOptions = [{value: null, label: 'By Site'}].concat([], editorSkins);

         const siteOptions = this.sites.some(s => s.id === this.account.siteId)
                  ? this.sites.map(s => ({value: s.id, label: s.name}))
                  : [{value: this.account.siteId, label: this.account.siteName}]; // Account is currently not in a site we can change, just allow no other choices,

         siteId =          { type: 'select', label: 'Site', valueLabels: siteOptions };
         version =         { type: 'select', label: 'Frontend Version', valueLabels: frontendVersionOptions }; /* uuid */
         defaultEditMode = { type: 'select', label: 'Edit Launch Mode', valueLabels: editLaunchModeOptions };
         skin =            { type: 'select', label: 'Skin', valueLabels: skinOptions }; /* uuid */
      }

      if (this.canChangeSite) {
         disabled = {
            type: 'toggle',
            defaultValue: false,
            label: 'Disabled and eligible for automatic deletion',
         };

         const billingGroupOptions = this.canChangeReseller
            ? this.billingGroups.map(group => ({value: group, label: group}))
            : [{value: this.account.billingGroup, label: this.account.billingGroup}];
         billingGroup = {
            label: 'Billing Group',
            type: this.canChangeReseller ? 'select' : 'text',
            readonly: !this.canChangeReseller,
            valueLabels: billingGroupOptions,
         };
         notes = { type: 'textarea', label: 'Notes' };
      }

      // Initialise schema in desired form display order
      this.schema = {
         disabled,
         siteId,
         version,
         defaultEditMode,
         skin,
         fullName,
         department,
         billingId,
         billingGroup,
         editLimit,
         message,
         notes,
      };
      // Remove undefined fields from form (restricted fields for non-admin/resellerManager/siteManager)
      Object.keys(this.schema).forEach(k => this.schema[k] === undefined && delete this.schema[k]);

      this.account.defaultEditMode = this.account.defaultEditMode || LaunchModeName.inherit;
      return this.formHelperService.buildForm(this.schema, this.account);
   }

   private submitForm(formAccountData: Partial<AccountDto>): Observable<AccountDto> {
      const initialSiteId = this.account.siteId;
      return this.accountsApiService.updateAccount(this.account.id, formAccountData).pipe( tap(newAccount => {
         if (newAccount.siteId && newAccount.siteId !== initialSiteId) {
            this.navigationService.reload(
               'Account data has been saved successfully. Page was reloaded because the account\'s site was changed',
               'id_account_data',
            );
         }
      }));
   }

   private showError(error: string, resource: string, action='loading'): void {
      this.alertService.show({
         text: `An error occurred while ${action} ${resource}: ${error || 'unknown'}`,
         type: 'danger',
      });
   }
}
