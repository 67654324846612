<div *ngIf="title" >
   <h4 mat-dialog-title class="confirm-dialog-title">
      {{title}}
   </h4>
</div>

<div *ngIf="message">
   <mat-dialog-content class="confirm-dialog-message">
      <p>{{message}}</p>
   </mat-dialog-content>
</div>

<mat-dialog-actions align="end" class="confirm-dialog-actions">
   <button *ngIf="cancelBtnLabel" mat-raised-button (click)="onDismiss()">{{cancelBtnLabel}}</button>
   <button mat-raised-button color="primary" (click)="onConfirm()">{{confirmBtnLabel}}</button>
</mat-dialog-actions>
