import { HttpClient, HttpParams, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { Observable, forkJoin } from 'rxjs';

import { Me, UsersSearchParams } from '../../store/users';
import { RAW_DATA } from '../constants/raw-data';
import { UserSummary, ChangeableFields } from '../types';
import { ImportedUser } from '../../site/users/imported-user.interface';
import { map } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';


interface NotificationSettings {
   type: 'email' | 'reviewapp';
}

export interface CSVUser {
   company: string;
   email: string;
   fullname: string;
}

interface UserImportData {
   dryRun: boolean;
   siteId: string;
   users: CSVUser[];
}

export type UpdateNotificationSettingsDto = {
   add?: NotificationSettings[];
   remove?: NotificationSettings[];
};

type StringifiedUserSearchParams = {
   [K in keyof UsersSearchParams]: string;
};

export type ImportedUserObservable = Observable<ImportedUser[]>;

@Injectable({
   providedIn: 'root',
})
export class UsersApiService {
   constructor(private http: HttpClient, private alertService: AlertService) {}

   public findUsers(searchParams: UsersSearchParams, rawResponse = true): Observable<User[]> {
      const params: StringifiedUserSearchParams = pick(searchParams, ['siteId', 'accountId', 'searchText', 'type']);
      if (searchParams.offset) {
         params.offset = searchParams.offset.toString();
      }
      params.limit = '10';

      const options = {
         params: new HttpParams({fromObject: params}),
         headers: rawResponse ? RAW_DATA : {},
      };
      return this.http.get<User[]>('/api/users', options);
   }

   public fetchUser(userId: string): Observable<User> {
      return this.http.get<User>(`/api/users/${userId}`);
   }

   public updateUserFields(userId: string, changedFields: ChangeableFields): Observable<UserSummary> {
      const api = `/api/users/${userId}`;
      return this.http.post<ChangeableFields>(api, changedFields);
   }

   public fetchMe(): Observable<Me> {
      return this.http.get<Me>('/api/me');
   }

   public fetchNotificationSettings(userId: string): Observable<NotificationSettings[]> {
      return this.http.get<NotificationSettings[]>(`/api/users/${userId}/notificationSettings`);
   }

   public updateNotificationSettings(userId: string, data: UpdateNotificationSettingsDto): Observable<void> {
      return this.http.post<void>(`/api/users/${userId}/notificationSettings`, data);
   }

   public createUsers(importedUsers: CSVUser[], siteId: string, dryRun = false): ImportedUserObservable{
      const data: UserImportData = {
         users: importedUsers,
         dryRun: dryRun,
         siteId: siteId
      };
      return this.http.post<ImportedUser[]>('/api/users', data);
   }

   public importUsers(importedUsers: CSVUser[], siteId: string, dryRun = false, reportUsers): ImportedUserObservable[]{
      const retObservables = [];
      const chunkSize = 100;
      for (let i = 0; i < importedUsers.length; i += chunkSize) {
         const chunk = importedUsers.slice(i, i + chunkSize);
         retObservables.push(
            this.createUsers(chunk, siteId, dryRun).pipe(
               this.alertService.notifyOnError(),
               map(users=> reportUsers(users))
            )
         );
      }
      return retObservables;
   }
}
