import AsyncCacheModule from '../utils/async-cache.factory';
import SkeletonCacheModule from '../utils/skeleton-cache.factory';
import SaveTransactionModule from '../utils/save-transaction';
import EndpointsApiModule from './endpoints.api.factory';
import ArrivalsFoldersApiModule from './account-users-api.factory';
import HttpModule from '../fbdn-angular-modules/http';
import { ArrivalsFolder } from './arrivals-folder.interface';
import { ArrivalsFoldersApi } from './arrivals-folders-api.factory.js';
import { Skeleton } from '../types/skeleton.interface.js';

const ngModule = angular.module('bb.api.arrivalsfolder-endpoint-assignments', [
   AsyncCacheModule.name,
   SkeletonCacheModule.name,
   SaveTransactionModule.name,
   EndpointsApiModule.name,
   ArrivalsFoldersApiModule.name,
   HttpModule.name,
]);

export interface ArrivalsFolderEndpointAssignmentsApi {
   fetchForAccount(accountId: string, forceFetch: boolean): void;
   fetchForService(service: Required<{id: string}>, forceFetch?: boolean): Promise<any>;
   getNewAssignment(arrivalsFolder: ArrivalsFolder, endpoint: any[]): any;
   createAssignment(assignment: Assignment): Promise<any>;
   createAssignments(assignments: Assignment[]): Promise<any>;
   deleteAssignment(assignments: Assignment[], assignment: Assignment): any;
}

interface Assignment {
   $$deleting: boolean;
   arrivalsFolder: Skeleton;
   endpoint: any;
   arrivalsFolderId?: string;
   endpointId?: string;
}

ngModule.factory('ArrivalsFolderEndpointAssignmentsApi',
      ['$q', 'AsyncCache', 'ApiUpdates', 'SkeletonCache', 'SaveTransaction', 'EndpointsApi', 'ArrivalsFoldersApi', 'http', '_',
       function($q: IQService, AsyncCache: any, ApiUpdates: any, SkeletonCache: any, SaveTransaction: any, EndpointsApi: any,
                arrivalsFoldersApi: ArrivalsFoldersApi, http: any, _: Lodash): ArrivalsFolderEndpointAssignmentsApi {
   const api: ArrivalsFolderEndpointAssignmentsApi = this;
   var asyncCache = AsyncCache('ArrivalsFolderEndpointAssignmentsApi');
   var apiUpdates = ApiUpdates('ArrivalsFolderEndpointAssignmentsApi');
   var skeletonCache = SkeletonCache('ArrivalsFolderEndpointAssignmentsApi', {
      unmarshall: (assignment: Assignment) => {
         if (!assignment.endpoint) {
            assignment.endpoint = EndpointsApi.getSkeleton(assignment.endpointId);
            delete assignment.endpointId;
         }
         if (!assignment.arrivalsFolder) {
            assignment.arrivalsFolder = arrivalsFoldersApi.getSkeleton(assignment.arrivalsFolderId);
            delete assignment.arrivalsFolderId;
         }
         return SaveTransaction.prepare(assignment);
      },
      marshall: (assignment: Assignment) => {
         return {
            endpointId: assignment.endpoint.id,
            arrivalsFolderId: assignment.arrivalsFolder.id
         };
      }
   });

   api.fetchForAccount = (accountId, forceFetch) => {
      return asyncCache.fetch('account-arrivals-folders-endpoint-assignments-' + accountId, () => {
         return http({
            method: 'GET',
            url: '/api/accounts/' + accountId + '/arrivalsFolderEndpointAssignments',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, api.fetchForAccount, [accountId, true]));
   };

   api.fetchForService = (service, forceFetch) => {
      return asyncCache.fetch('service-arrivals-folders-endpoint-assignments-' + service.id, () => {
         return http({
            method: 'GET',
            url: '/api/services/' + service.id + '/arrivalsFolderEndpointAssignments',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, api.fetchForService, [service, true]));
   };

   api.getNewAssignment = (arrivalsFolder, endpoint) => {
      return SaveTransaction.prepare({
         $$state: 'new',
         $$dirty: true,
         arrivalsFolder: arrivalsFolder,
         endpoint: endpoint
      });
   };

   api.createAssignment = assignment => {
      return SaveTransaction.saveObject(assignment, () => {
         return http({
            method: 'POST',
            url: '/api/arrivalsFolderEndpointAssignments',
            data: skeletonCache.marshall(assignment)
         });
      });
   };

   api.deleteAssignment = (assignments, assignment) => {
      if (!assignment.arrivalsFolder.id) {
         _.pull(assignments, assignment);
         return $q.when();
      }
      assignment.$$deleting = true;
      return http({
         method: 'POST',
         url: '/api/arrivalsFolderEndpointAssignments/trash',
         data: skeletonCache.marshall(assignment)
      }).then( results => {
         _.pull(assignments, assignment);
         return results;
      }, error => {
         // Could be other identical assignments created that are saving / saved
         _.remove(assignments, (existing: any) => {
            return existing !== assignment && !assignment.$$deleting && existing.arrivalsFolder === assignment.arrivalsFolder && existing.endpoint === assignment.endpoint;
         });
         assignment.$$deleting = false;
         return $q.reject(error);
      });
   };

   api.createAssignments = assignments => {
      if (!assignments.length) return $q.when();
      return SaveTransaction.saveCollection(assignments, () => {
         return http({
            method: 'POST',
            url: '/api/arrivalsFolderEndpointAssignments',
            data: (_.partialRight(_.map, skeletonCache.marshall) as any)(assignments)
         });
      });
   };

   return api;
}]);

export default ngModule;
