import { downgradeComponent } from '@angular/upgrade/static';

/* Pure Angular */
import { FormButtonsComponent } from '../shared/components/form-buttons/form-buttons.component';
import { PublishLocationSelectorComponent } from './publish-location-selector.component';

/* angular.js */
import PublishingButtonApiModule from './publishing-button-api.factory';
import NewPublishingButtonDialogModule from './new-publishing-button-dialog.controller';
import { jointDiagramDirective } from './joint-diagram.directive';
import { publishingButtonsManagerComponent } from './publishing-buttons-manager.component.ajs';

const ngModule = angular.module('bb.publishing-buttons-manager', [
   PublishingButtonApiModule.name,
   NewPublishingButtonDialogModule.name,
]);

ngModule
   .directive('jointDiagram', jointDiagramDirective)
   .directive('bbFormButtons', downgradeComponent({component: FormButtonsComponent}))
   .directive('bbPublishLocationSelector', downgradeComponent({component: PublishLocationSelectorComponent}))
   .component('publishingButtonsManager', publishingButtonsManagerComponent);

export default ngModule;
