import AsyncCacheModule from '../utils/async-cache.factory';
import SaveTransactionModule from '../../shared/utils/save-transaction';
import HttpModule from '../fbdn-angular-modules/http';
import { Skeleton } from '../types/skeleton.interface';
import { ArrivalsFolder } from './arrivals-folder.interface';

const ngModule = angular.module('bb.api.arrivals-folders', [
   AsyncCacheModule.name,
   SaveTransactionModule.name,
   HttpModule.name,
]);

interface Results {
   accountId: string;
   id: string;
   name: string;
   options: any;
   priority: number;
   typeId: string;
}

interface Cache {
   [key: string]: Skeleton | ArrivalsFolder;
}

export interface ArrivalsFoldersApi {
   fetchTypesForAccount(accountId: string): any;
   fetch(arrivalsFolderId: string): any;
   fetchForAccount(accountId: string): any;
   createDefaultArrivalsFolder(accountId: string, name: string): any;
   getNewArrivalsFolder(accountId: string, typeId: any, priority: number, schema: any): ArrivalsFolder;
   createArrivalsFolder(accountId: string, arrivalsFolder: ArrivalsFolder): any;
   saveArrivalsFolder(arrivalsFolder: ArrivalsFolder): Promise<any>;
   saveArrivalsFolderName(arrivalsFolder: ArrivalsFolder): Promise<any>;
   deleteArrivalsFolder(arrivalsFolder: ArrivalsFolder, arrivalsFolders: ArrivalsFolder[]): any | Promise<any>;
   savePriorities(accountId: string, arrivalsFolders: ArrivalsFolder): Promise<any>;
   getSkeleton(arrivalsFolderId: string): Skeleton;
}

ngModule.factory('ArrivalsFoldersApi', ['$q', 'AsyncCache', 'SaveTransaction', 'http', '_', 'DynamicSchema',
                                        function($q: IQService, AsyncCache: any, SaveTransaction: any, http: any, _: Lodash, DynamicSchema: any): ArrivalsFoldersApi {
   const api: ArrivalsFoldersApi = this;
   const asyncCache = AsyncCache('ArrivalsFoldersApi');
   const cache: Cache = {};
   let id = 0;

   function _unmarshall(arrivalsFolder: Partial<ArrivalsFolder>): ArrivalsFolder {
      SaveTransaction.prepare(arrivalsFolder, ['options']);
      return _mergeIntoSkeleton(arrivalsFolder as ArrivalsFolder);
   }

   function _marshall(arrivalsFolder: ArrivalsFolder): Omit<ArrivalsFolder, 'id'> {
      return _.omit(arrivalsFolder, 'id');
   }

   api.getSkeleton = (arrivalsFolderId) => {
      if (arrivalsFolderId in cache) return cache[arrivalsFolderId];
      const skeleton = {
         id: arrivalsFolderId ? arrivalsFolderId : null,
         $$state: arrivalsFolderId ? 'uninitialised' : 'clean'
      };
      if (arrivalsFolderId) cache[arrivalsFolderId] = skeleton;
      return skeleton;
   };

   function _mergeIntoSkeleton(arrivalsFolder: ArrivalsFolder): ArrivalsFolder {
      const skeleton = api.getSkeleton(arrivalsFolder.id);
      skeleton.$$state = 'clean';
      return _.extend(skeleton, arrivalsFolder);
   }

   api.fetchTypesForAccount = (accountId) => {
      return asyncCache.fetch('arrivals-folder-types-' + accountId, {
         method: 'GET',
         url: '/api/accounts/' + accountId + '/arrivalsFolderTypes'
      }).then(_.partialRight(_.keyBy, 'id'));
   };

   api.createDefaultArrivalsFolder = (accountId, name) => {
      return http({
         method: 'POST',
         url: '/api/accounts/' + accountId + '/arrivalsFolders',
         data: {
            name: name
         }
      });
   };

   api.fetchForAccount = (accountId) => {
      return asyncCache.fetch('account-arrivals-folders-' + accountId, {
         method: 'GET',
         url: '/api/accounts/' + accountId + '/arrivalsFolders'
      }).then(_.partialRight(_.map, _unmarshall));
   };

   api.fetch = (arrivalsFolderId) => {
      return asyncCache.fetch('account-arrivals-folder-' + arrivalsFolderId, {
         method: 'GET',
         url: '/api/arrivalsFolders/' + arrivalsFolderId
      }).then(_unmarshall);
   };

   api.getNewArrivalsFolder = (accountId, typeId, priority, schema) => {
      return _unmarshall({
         $$temporaryId: 'new-arrivals-folder-' + ++id,
         $$state: 'new',
         $$dirty: true,
         accountId: accountId,
         typeId: typeId,
         name: 'New arrivals folder',
         priority: priority,
         options: DynamicSchema.defaultValuesForSchema(schema),
      });
   };

   api.createArrivalsFolder = (accountId, arrivalsFolder) => {
      return SaveTransaction.saveObject(arrivalsFolder, () => {
         return http({
            method: 'POST',
            url: '/api/accounts/' + accountId + '/arrivalsFolders',
            data: _marshall(arrivalsFolder)
         }).then((results: Results) => {
            arrivalsFolder.id = results.id;
            cache[arrivalsFolder.id] = arrivalsFolder;
            return results;
         });
      });
   };

   api.saveArrivalsFolder = (arrivalsFolder) => {
      return SaveTransaction.saveObject(arrivalsFolder, function() {
         return http({
            method: 'POST',
            url: '/api/arrivalsFolders/' + arrivalsFolder.id,
            data: arrivalsFolder
         });
      });
   };

   api.saveArrivalsFolderName = (arrivalsFolder) => {
      return SaveTransaction.save(arrivalsFolder, 'name', function() {
         return http({
            method: 'POST',
            url: '/api/arrivalsFolders/' + arrivalsFolder.id,
             data: {
               id: arrivalsFolder.id,
               name: arrivalsFolder.name
            }
         });
      });
   };

   api.deleteArrivalsFolder = (arrivalsFolder, arrivalsFolders) => {
      arrivalsFolder.$$deleting = true;
      const isNewArrivalsFolder = (arrivalsFolder.$$state === 'new' || arrivalsFolder.$$state === 'creating');
      return (isNewArrivalsFolder ? $q.when() : http({
         method: 'POST',
         url: '/api/arrivalsFolders/' + arrivalsFolder.id + '/trash'
      })).then((results) => {
         _.pull(arrivalsFolders, arrivalsFolder);
         return results;
      }, (error) => {
         arrivalsFolder.$$deleting = false;
         return $q.reject(error);
      });
   };

   api.savePriorities = (accountId, arrivalsFolders) => {
      return SaveTransaction.saveCollectionKey(arrivalsFolders, 'priority', function() {
         return http({
            method: 'POST',
            url: '/api/accounts/' + accountId + '/arrivalsFolders',
            data: _.map(arrivalsFolders, _.partialRight(_.pick, ['id', 'priority']))
         });
      });
   };

   return api;
}]);

export default ngModule;
