import FbdnPopNextToFactoryModule from '../../shared/fbdn-pop-next-to/fbdn-pop-next-to.factory';
import PageFactoryModule from '../../shared/utils/page.factory';
import StatusApiModule from '../../shared/api/status-api.factory';
import { ErrorHandler } from '../../shared/utils/error-handler';
import { NotificationService } from '../../shared/services/notification.service';

const ngModule = angular.module('bb.tab-status', [
   FbdnPopNextToFactoryModule.name,
   StatusApiModule.name,
   PageFactoryModule.name,
]);

ngModule.component('tabStatus', {
   templateUrl: '/furniture/js/app/src/side-bar/tab-status/tab-status.component.html',
   transclude: true,
   controllerAs: 'vm',
   controller: ['fbdnPopNextTo', 'Page', 'StatusApi', '_', '$scope', '$element', 'NotificationService',
               function(fbdnPopNextTo, Page: Page, StatusApi, _: Lodash, $scope, $element: IRootElementService, notificationService: NotificationService) {
      const vm = this;

      let fetcher;
      let popover = null;

      vm.showAll = false;
      vm.model = {
         ready: false,
         objects: null,
         filteredObjects: []
      };

      if (Page.context === 'site') {
         fetcher = _.partial(StatusApi.fetchSiteStatus, Page.siteId);
      } else if (Page.context === 'account') {
         fetcher = _.partial(StatusApi.fetchAccountStatus, Page.accountId);
      }

      function isSameObject(object1, object2) {
         return object1 === object2;
      }

      function filter() {
         let filteredObjects = _.filter(vm.model.objects, function(object) {
            return vm.showAll || object.status==='error';
         });

         const FRACTION = 0.5;

         // Order by name then ID
         filteredObjects = _.sortBy(filteredObjects, object => object.name);
         filteredObjects = _.sortBy(filteredObjects, object => object.id);

         // Order into ingest, services then endpoints
         const SORT_KEYS = {
            EdgeServer: 1,
            Service: 2,
            Endpoint: 3,
         };
         filteredObjects = _.sortBy(filteredObjects, object => SORT_KEYS[object.type] || 0);

         // Put services after their server if it is the list
         filteredObjects.forEach((object, index) => {
            object.$$statusIndex = index;
            object.$$statusLevel = 1;
         });
         filteredObjects.forEach(object => {
            if (object.type !== 'Service') return;

            var serverInList = _.find(filteredObjects, _.partial(isSameObject, object.edgeServer));
            if (!serverInList) return;

            object.$$statusIndex = serverInList.$$statusIndex + FRACTION;
            object.$$statusLevel = serverInList.$$statusLevel + 1;
         });
         filteredObjects = _.sortBy(filteredObjects, object => object.$$statusIndex);

         // Put endpoints after their service
         // If service not in the list, tries to put after server if in list
         filteredObjects.forEach((object, index) => {
            object.$$statusIndex = index;
         });
         filteredObjects.forEach(object => {
            if (object.type !== 'Endpoint') return;

            const serviceInList = filteredObjects.find(_.partial(isSameObject, object.service));
            const serverInList = filteredObjects.find(_.partial(isSameObject, object.service.edgeServer));
            const itemInList = serviceInList || serverInList;
            if (!itemInList) return;
            object.$$statusIndex = itemInList.$$statusIndex + FRACTION;
            object.$$statusLevel = itemInList.$$statusLevel + 1;
         });
         filteredObjects = _.sortBy(filteredObjects, object => object.$$statusIndex);

         vm.model.filteredObjects = filteredObjects;
         $scope.$broadcast('reflow');
      }

      fetcher()
         .then((modelObjects) => {
            vm.model.objects = modelObjects;
            vm.model.ready = true;
            filter();
         })
         .catch(error => {
            notificationService.show({
               type: 'danger',
               text: `An error occurred fetching the ${Page.context} status: ${error.status} ${error.message}`
            });
         });

      vm.toggleShowAll = function() {
         vm.showAll = !vm.showAll;
         filter();
      };

      vm.filterObject = function(object) {
         return vm.showAll || object.status==='error';
      };

      vm.getUrl = function(object) {
         return StatusApi.getUrl(object);
      };

      vm.toggleStatus = function() {
         if (popover) {
            popover.resolve();
            return;
         }
         fbdnPopNextTo.open({
            templateUrl: '/furniture/js/app/src/side-bar/tab-status/status-popover.template.html',
            attachElement: $element,
            controller: ['$scope', '$controllerData', function(scope: IScope, $controllerData) {
               angular.extend(scope, $controllerData);
            }],
            controllerData: {
               model: vm.model,
               toggleShowAll: vm.toggleShowAll,
               toggleStatus: vm.toggleStatus,
               showAll: vm.showAll,
               getUrl: vm.getUrl,
            },
            preventCloseOnClick: true,
            popoverClass: 'status'
         }).then(pop => {
            popover = pop;
            popover.result
               .catch(ErrorHandler.swallowModalCloseErrors)
               .finally(() => popover = null);
         });
      };
   }],
});

export default ngModule;
