import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NodeCard } from '../../media-manager/ng/media-manager/interfaces/node-card.interface';
import { ClipData } from '../../media-manager/ng/media-manager/interfaces/clip-data.interface';
import { NodesFlatTreeService } from '../../media-manager/ng/media-manager/services/nodes-flat-tree.service';
import { MediaInfo } from '../../media-manager/ng/media-manager/interfaces/media-node.interface';
import { UsersApiService } from '../api/users.api.service';
import { PageService } from './page.service';

export interface NodeData { //TODO make it more reusable by mapping the relevant data only from nodeCard
   name: string;
}

@Injectable({
   providedIn: 'root'
})
export class ReactplayerService {
   private playerUrlOrigin = new BehaviorSubject<string>('*');
   private mediaId = new BehaviorSubject<string>(null);
   private iframeUrl = new BehaviorSubject<SafeResourceUrl>(null);
   private title = new BehaviorSubject<string>('-');
   private nodeCard = new BehaviorSubject<NodeCard|null>(null);
   private mediaInfo = new BehaviorSubject<MediaInfo|null>(null);
   private dialogState = new BehaviorSubject<boolean>(false);
   private accountName = new BehaviorSubject<string>("");
   private clip = new BehaviorSubject<ClipData>(null);
   private Page: Page;
   constructor(
      private pageService: PageService,
      private usersApiService: UsersApiService,
      public domSanitizer: DomSanitizer,
      public nodesFlatTreeService: NodesFlatTreeService,
   ) {
      this.Page = this.pageService.page;
      this.usersApiService.fetchMe().pipe(
         switchMap(data => data.accounts.filter(item => item.id === this.Page.accountId)),
         tap(account => {
            this.accountName.next(account.name);
         }),
      ).subscribe();
   }

   //Getter and Setters
   public getIframeUrl(): Observable<SafeResourceUrl> {
      return this.iframeUrl;
   }

   public updateIframeUrl(accountName?: string, accountId?: string) {
      const acctName = (accountName !== undefined) ? accountName : this.accountName.value;
      const acctId = ( accountId !== undefined) ? accountId : this.Page.accountId;
      const safeUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.origin + '/accounts/' + acctId + '/reactvideoplayer?accountName=' + acctName);
      this.iframeUrl.next(safeUrl);
   }

   public getPlayerUrlOrigin(): Observable<string> {
      return this.playerUrlOrigin.asObservable();
   }

   public setPlayerUrlOrigin(newUrlOrigin: string) {
      this.playerUrlOrigin.next(newUrlOrigin);
   }

   public getMediaId(): Observable<string> {
      return this.mediaId.asObservable();
   }

   public setMediaId(newMediaId: string) {
      this.mediaId.next(newMediaId);
      this.updateIframeUrl();
   }

   public getTitle(): Observable<string> {
      return this.title.asObservable();
   }

   public setTitle(newTitle: string) {
      this.title.next(newTitle);
   }

   public getNodeCard(): Observable<NodeCard|null> {
      return this.nodeCard.asObservable();
   }

   public setNodeCard(newNodeCard: NodeCard) {
      this.nodeCard.next(newNodeCard);
      this.updateIframeUrl();
   }

   public setMediaInfo(mediaInfo: MediaInfo) {
      this.mediaInfo.next(mediaInfo);
      this.updateIframeUrl(mediaInfo.accountName, mediaInfo.accountId);
   }

   public getMediaInfo(): Observable<MediaInfo|null> {
      return this.mediaInfo.asObservable();
   }

   public getStatus(): Observable<boolean> {
      return this.dialogState.asObservable();
   }

   public setStatus(newStatus: boolean) {
      this.dialogState.next(newStatus);
   }

   public isPlayableType(type: string): boolean {
      const playableTypes = ['edl', 'media', 'lifestream', 'timecode', 'still'];
      return playableTypes.includes(type);
   }

   // Clip creation async Example usage:
   // if(this.reactplayerService.getClip()) this.reactplayerService.getClip().unsubscribe()
   // just make sure you unsubscribe any previous version before init a new one.
   // this.reactplayerService.initClip();
   // this.reactplayerService.getClip.subscribe( data => {
   //        this.reactplayerService.createClip(targetPath, data);
   // });
   public initClip() {
      this.clip = new BehaviorSubject(null);
   }

   public setClip(clipData: ClipData) {
      this.clip.next(clipData);
   }

   public getClip(): Observable<ClipData> {
      return this.clip;
   }

   public createClip(targetPath: string, clipData: ClipData) {
      this.nodesFlatTreeService.saveClipToFolderNode(targetPath, clipData);
   }
}
