import UpdatesApiModule from '../utils/api-updates.factory';
import ServicesApiModule from './services-api.factory';
import HttpModule from '../fbdn-angular-modules/http';
import SaveTransactionModule from '../utils/save-transaction';
import AsyncCacheModule from '../utils/async-cache.factory';
import SkeletonCacheModule from '../utils/skeleton-cache.factory';
import AccountsApiModule from './accounts/accounts-api.factory';

const ngModule = angular.module('bb.api.endpoints', [
   UpdatesApiModule.name,
   ServicesApiModule.name,
   SaveTransactionModule.name,
   HttpModule.name,
   AsyncCacheModule.name,
   SkeletonCacheModule.name,
   AccountsApiModule.name,
]);
// this is substituted by EndpointsApiService and have to be fully dismissed.
ngModule.factory('EndpointsApi', ['$q', 'ApiUpdates', 'ServicesApi', 'AccountsApi', 'SaveTransaction', 'http', 'AsyncCache', 'SkeletonCache', '_', 'DynamicSchema',
                                 function($q, ApiUpdates, ServicesApi, AccountsApi, SaveTransaction, http, AsyncCache, SkeletonCache, _, DynamicSchema) {
   var lastTemporaryId = 0;
   var asyncCache = AsyncCache('EndpointsApi');
   var skeletonCache = SkeletonCache('EndpointsApi', {
      marshall: function(endpoint) {
         return {
            id: endpoint.id,
            name: endpoint.name,
            typeId: endpoint.typeId,
            accountId: endpoint.account.id || null,
            siteId: endpoint.siteId,
            serviceId: endpoint.service.id,
            arrivalsFolderId: endpoint.arrivalsFolderId,
            workflowId: endpoint.workflowId,
            options: endpoint.options
         };
      },
      unmarshall: function(endpoint) {
         if (!endpoint.account) {
           endpoint.account = AccountsApi.getSkeleton(endpoint.accountId);
           delete endpoint.accountId;
         }
         if (!endpoint.service) {
            endpoint.service = ServicesApi.getSkeleton(endpoint.serviceId);
            delete endpoint.serviceId;
         }
         return SaveTransaction.prepare(endpoint, ['options']);
      }
   });
   var apiUpdates = ApiUpdates('EndpointsApi', function(endpoint) {
      fetch(endpoint.id, true);
   });

   function fetchEndpointType(endpointTypeId) {
      return asyncCache.fetch('edge-server-endpoint-type-' + endpointTypeId, {
         method: 'GET',
         url: '/api/endpointTypes/' + endpointTypeId
      });
   }

   function fetchEndpointTypesForService(serviceId) {
      return asyncCache.fetch('edge-server-endpoint-types-' + serviceId, {
         method: 'GET',
         url: '/api/services/' + serviceId + '/endpointTypes'
      }).then(function(endpointTypes) {
         return _.keyBy(endpointTypes, 'id');
      });
   }

   function fetch(endpointId: string, forceFetch = false) {
      if (!endpointId) throw new TypeError(`endpointId is ${endpointId}`);
      return asyncCache.fetch('endpoint-' + endpointId, function() {
         return http({
            method: 'GET',
            url: '/api/endpoints/' + endpointId
         }).then(skeletonCache.unmarshall);
      }, forceFetch).then(apiUpdates.subscribe);
   }

   function fetchForService(service, forceFetch) {
      return asyncCache.fetch('service-' + (service.$$temporaryId || service.id), function() {
         return !service.id ? $q.when({results: []}) : http({
            method: 'GET',
            url: '/api/services/' + service.id + '/endpoints',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, fetchForService, [service, true]));
   }

   function fetchForServer(server, forceFetch) {
      return asyncCache.fetch('server-' + (server.$$temporaryId || server.id), function() {
         return !server.id ? $q.when({results: []}) : http({
            method: 'GET',
            url: '/api/edgeServers/' + server.id + '/endpoints',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, fetchForServer, [server, true]));
   }

   function fetchForAccount(accountId, forceFetch) {
      return asyncCache.fetch('account-' + accountId, function() {
         return http({
            method: 'GET',
            url: '/api/accounts/' + accountId + '/endpoints',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, fetchForAccount, [accountId, true]));
   }

   function getNewEndpoint(service, endpointType) {
      const endpoint = skeletonCache.unmarshall({
         $$state: 'new',
         $$temporaryId: 'new-endpoint-' + ++lastTemporaryId,
         id: null,
         typeId: endpointType.id,
         name: 'New ' + endpointType.name,
         service: service,
         accountId: null,
         siteId: null,
         options: DynamicSchema.defaultValuesForSchema(endpointType.schema),
      });
      return endpoint;
   }

   function createEndpoint(endpoint) {
      endpoint.pendingSettings = endpoint.online;
      return SaveTransaction.saveObject(endpoint, function() {
         return http({
            method: 'POST',
            url: '/api/services/' + endpoint.service.id + '/endpoints',
            data: skeletonCache.marshall(endpoint)
         }).then(function(results) {
            endpoint.id = results.id;
            skeletonCache.add(endpoint);
            return results;
         }).then(skeletonCache.unmarshall).then(apiUpdates.subscribe);
      });
   }

   function saveEndpoint(endpoint) {
      if (!endpoint.id) throw new TypeError(`endpoint.id is ${endpoint.id}`);
      endpoint.pendingSettings = endpoint.online;
      return SaveTransaction.saveObject(endpoint, function() {
         return http({
            method: 'POST',
            url: '/api/endpoints/' + endpoint.id,
            data: skeletonCache.marshall(endpoint)
         }).then(skeletonCache.unmarshall);
      });
   }

   function performAction(endpoint, action, data) {
      return http({
         method: 'POST',
         url: '/api/endpoints/' + endpoint.id + '/actions/' + action,
         data: data || {}
      });
   }

   function saveName(endpoint) {
      if (!endpoint.id) throw new TypeError(`endpoint.id is ${endpoint.id}`);
      endpoint.pendingSettings = endpoint.online;
      return SaveTransaction.save(endpoint, 'name', function(e, key) {
         return http({
            method: 'POST',
            url: '/api/endpoints/' + e.id,
            data: {
               name: e[key]
            }
         }).then(skeletonCache.unmarshall);
      });
   }

   function deleteEndpoint(endpoint): Promise<any> {
      endpoint.$$deleting = true;
      return (!endpoint.id ? $q.when() : http({
         method: 'POST',
         url: '/api/endpoints/' + endpoint.id + '/trash'
      }))
      .catch(error => {
         endpoint.$$deleting = false;
         return $q.reject(error);
      });
   }

   function _saveRecording(endpoint) {
      if (!endpoint.id) throw new TypeError(`endpoint.id is ${endpoint.id}`);
      return SaveTransaction.save(endpoint, 'recording', function() {
         return http({
            method: 'POST',
            url: '/api/endpoints/' + endpoint.id,
            data: _.pick(endpoint, ['id', 'recording'])
         });
      });
   }

   function startRecording(endpoint) {
      endpoint.recording = true;
      return _saveRecording(endpoint);
   }

   function stopRecording(endpoint) {
      endpoint.recording = false;
      return _saveRecording(endpoint);
   }

   return {
      fetchEndpointTypesForService: fetchEndpointTypesForService,
      fetchEndpointType: fetchEndpointType,
      fetchForService: fetchForService,
      fetchForServer: fetchForServer,
      fetchForAccount: fetchForAccount,
      fetch: fetch,
      unmarshall: skeletonCache.unmarshall,
      getNewEndpoint: getNewEndpoint,
      createEndpoint: createEndpoint,
      saveEndpoint: saveEndpoint,
      performAction: performAction,
      saveName: saveName,
      deleteEndpoint: deleteEndpoint,
      startRecording: startRecording,
      stopRecording: stopRecording,
      getSkeleton: skeletonCache.get,
      populateSkeleton: function(skeleton) {
         return fetch(skeleton.id);
      }
   };
}]);

export default ngModule;
