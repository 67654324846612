import './updates.factory';

const ngModule = angular.module('bb.utils.api-updates', ['bb.utils.updates']);

ngModule.factory('ApiUpdates', ['_', 'Updates', function(_, Updates) {
   var globalUpdates = {};

   return function(namespace, modelFetcher) {
      globalUpdates[namespace] = globalUpdates[namespace] || {
         updates: Updates(function(update) {
            globalUpdates[namespace].updaters[update.channel](update.data);
         }),
         updaters: {}
      };

      var updates = globalUpdates[namespace].updates;
      var updaters = globalUpdates[namespace].updaters;

      function _registerUpdater(channel, updater) {
         updaters[channel] = updater;
      }

      function _registerModelUpdater(model) {
         _registerUpdater(model._channel, function() {
            modelFetcher(model);
         });
         return model;
      }

      function subscribe(model) {
         _registerModelUpdater(model);
         updates.subscribe([{channel: model._channel}]);
         return model;
      }

      // Subscribe to a channel that triggers incremental updates for
      // some thing, using some kind of update offset data. Only one
      // callback to updater will run at once, another will not start
      // until updater's promise resolves. updater must return the new
      // updateOffset token which will be passed back in to it. Only
      // one updater is supported per channel - new subscribes will
      // override old ones.
      function subscribeToIncrementalUpdates(updater, channel, initialUpdateOffset) {
         var updateOffset = initialUpdateOffset; // null during a running update
         var pendingUpdate = false; // set if updates come in while an update is running

         function update() {
            let offset = updateOffset;
            if (!offset) {
               pendingUpdate = true;
               return;
            }
            updateOffset = null;
            updater(offset).then(newUpdateOffset => {
               updateOffset = newUpdateOffset;
               if (pendingUpdate) {
                  pendingUpdate = false;
                  update();
               }
            });
         }

         _registerUpdater(channel, update);
         updates.subscribe([{channel: channel}]);
      }

      function subscribeToCollection(rawCollection, fetcher, fetcherArgs) {
         var channels = [];

         _.forEach(rawCollection.results, function(model) {
            if (model._channel) {
               _registerModelUpdater(model);
               channels.push({channel: model._channel});
            }
         });

         if (rawCollection.channel && fetcher) {
            _registerUpdater(rawCollection.channel, function() {
               fetcher.apply(null, fetcherArgs);
            });
            channels.push({channel: rawCollection.channel});
         }

         updates.subscribe(channels);

         return rawCollection.results;
      }

      return {
         subscribe: subscribe,
         subscribeToCollection: subscribeToCollection,
         subscribeToIncrementalUpdates: subscribeToIncrementalUpdates
      };
   };
}]);

export default ngModule;
