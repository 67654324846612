import PageModule from './page.factory';

const ngModule = angular.module('bb.service.make-url', [
   PageModule.name,
]);

export class UrlService {
   static $inject = ['Page'];

   constructor(private page: Page) {}

   public makeUrl(name: string, params: {[key: string]: string}): string {
      // Must correspond to routing.py
      if (name === 'edge-server') {
         return '/users/' + params.userId + '/edge#/' + params.edgeServerId;
      }
      if (name === 'edge-server-ingest-queue') {
         return '/edge/' + params.edgeServerId + '/ingestQueue?context=' + this.page.context + '#/forEdge/' + params.edgeServerId + (params.filter ? '?filter=' + params.filter : '');
      }
      if (name === 'edge-server-unprocessed-files') {
         return '/edge/' + params.edgeServerId + '/unprocessedFiles?context=' + this.page.context;
      }
      if (name === 'edge-server-services') {
         return '/edge/' + params.edgeServerId + '/services?context=' + this.page.context;
      }
      if (name === 'edge-server-endpoints') {
         return '/edge/' + params.edgeServerId + '/services/' + params.serviceId + '/endpoints?context=' + this.page.context;
      }
      if (name === 'edge-server-endpoint') {
         return '/edge/' + params.edgeServerId + '/services/' + params.serviceId + '/endpoints?context=' + this.page.context + '#/' + params.endpointId;
      }
      if (name === 'arrivalsFolders') {
         return '/accounts/' + params.accountId + '/arrivals';
      }
      if (name === 'arrivalsFolder') {
         return '/accounts/' + params.accountId + '/arrivals#' + params.arrivalsFolderId;
      }
      if (name === 'arrivals-folder-ingest-queue') {
         return '/accounts/' + params.accountId + '/arrivals/' + params.arrivalsFolderId + '/ingestQueue' + '#/forArrivals/' + params.arrivalsFolderId + (params.filter ? '?filter=' + params.filter : '');
      }
      if (name === 'arrivals-folder-unprocessed-files') {
         return '/accounts/' + params.accountId + '/arrivals/' + params.arrivalsFolderId + '/unprocessedFiles';
      }
      if (name === 'user') {
         return '/users/user_settings/' + params.userId + '/';
      }
      if (name === 'account-settings') {
         return '/accounts/' + params.accountName + '/';
      }
      throw new Error('makeUrl unknown url name ' + name);
   }
}

ngModule.service('UrlService', UrlService);

export default ngModule;
