import { AsyncCache } from '../utils/async-cache.factory';
import { SaveTransaction } from '../utils/save-transaction/save-transaction.factory';
import { IngestQueueItem, Filter, Search } from '../../ingest-queues/interfaces';

import EndpointsApiModule from '../api/endpoints.api.factory';
import ServicesApiModule from '../api/services-api.factory';
import AsyncCacheModule from '../utils/async-cache.factory';
import SkeletonCacheModule from '../utils/skeleton-cache.factory';
import ArrivalsFolderMogule from '../api/arrivals-folders-api.factory';

const ngModule = angular.module('bb.api.ingest-queue-items', [
   EndpointsApiModule.name,
   ServicesApiModule.name,
   AsyncCacheModule.name,
   SkeletonCacheModule.name,
   ArrivalsFolderMogule.name,
]);

interface Sort {
   key: string;
   direction: number;
}

export class IngestQueueItemsApi {
   static $inject = ['ApiUpdates', 'EndpointsApi', 'ServicesApi', 'AsyncCache', 'SkeletonCache', '$q', 'http', 'SaveTransaction', '_'];

   private cache: any;
   private skeletonCache: any;
   private apiUpdates: any;

   constructor(
      ApiUpdates: any,
      endpointsApi: any,
      servicesApi: any,
      asyncCache: AsyncCache,
      SkeletonCache: any,
      private $q: IQService,
      private http: any,
      private saveTransaction: SaveTransaction,
      private _: Lodash,
   ) {
      const moment = window.moment;
      this.cache = asyncCache('IngestQueueItemsApi');
      this.skeletonCache = SkeletonCache('IngestQueueItemsApi', {
         unmarshall: (queueItem: IngestQueueItem) => {
            if (queueItem.endpointId && !queueItem.endpoint) {
               queueItem.endpoint = endpointsApi.getSkeleton(queueItem.endpointId);
               delete queueItem.endpointId;
               endpointsApi.populateSkeleton(queueItem.endpoint).then(() => {
                  servicesApi.populateSkeleton(queueItem.endpoint.service);
               });
            }
            if (queueItem.scheduledStartTime) {
               queueItem.scheduledStartTime = moment(queueItem.scheduledStartTime);
               queueItem.priority = -queueItem.scheduledStartTime.unix();
            }
            if (queueItem.whenQueued) {
               queueItem.whenQueued = moment(queueItem.whenQueued);
            }
            if (queueItem.whenStarted) {
               queueItem.whenStarted = moment(queueItem.whenStarted);
            }
            if (queueItem.whenFinished) {
               queueItem.whenFinished = moment(queueItem.whenFinished);
            }
            if (queueItem.duration) {
               queueItem.duration = moment.duration(queueItem.duration, 'seconds');
            }
            if (queueItem.proxyWidth && queueItem.proxyHeight) {
               queueItem.playable = true;
            }
            return this.saveTransaction.prepare(queueItem);
         }
      });
      this.apiUpdates = ApiUpdates('IngestQueueItemsApi');
   }

   public fetchForArrivalsFolder(arrivalsFolderId: string, offset: string, sort: Sort, filters: Filter, search: Search, handler, errorHandler): void {
      const filterString = this._.keys(this._.pickBy(filters, this._.identity)).join('|');
      const asyncCacheKey = 'ingest-queue-items-for-arrivals-folder-' + arrivalsFolderId + '-' + offset + '-' + sort.key + '-' + sort.direction + '-' + filterString + '-' + search.term;
      const url = '/api/arrivalsFolders/' + arrivalsFolderId + '/ingestQueueItems';
      this.fetchAndSubscribe(url, asyncCacheKey, offset, sort, filterString, search, handler, errorHandler);
   }

   public fetchForEdgeServer(edgeServerId: string, offset: string, sort: Sort, filters: Filter, search: Search, handler, errorHandler): void {
      const filterString = this._.keys(this._.pickBy(filters, this._.identity)).join('|');
      const asyncCacheKey = 'ingest-queue-items-for-edge-server-' + edgeServerId + '-' + offset + '-' + sort.key + '-' + sort.direction + '-' + filterString + '-' + search.term;
      const url = '/api/edgeServers/' + edgeServerId + '/ingestQueueItems';
      this.fetchAndSubscribe(url, asyncCacheKey, offset, sort, filterString, search, handler, errorHandler);
   }

   public queueItemCommand(queueItem: Partial<IngestQueueItem>, command: string, data?: Partial<IngestQueueItem>): Promise<any> {
      return this.queueItemPost(angular.extend({}, data || {}, { id: queueItem.id, action: command }));
   }

   public saveQueueItemStatus(queueItem: IngestQueueItem, status: string): any {
      queueItem.status = status;
      return this.saveTransaction.save(queueItem, 'status', () => {
         return this.queueItemPost(this._.pick(queueItem, ['id', 'status'])).then(() => {
            if (queueItem.status !== status) {
               return this.$q.reject(queueItem.error);
            }
         });
      });
   }

   public saveQueueItemNames(queueItem: IngestQueueItem): Promise<any> {
      return this.saveTransaction.saveObject(queueItem, () => {
         return this.queueItemPost(this._.pick(queueItem, ['id', 'reelName', 'diskLabel', 'clipName']));
      });
   }

   public saveQueueItemsStatus(queueItems: IngestQueueItem[], status: string): Promise<any> {
      this._.forEach(queueItems, (queueItem) => {
         queueItem.status = status;
      });
      return this.saveTransaction.saveCollectionKey(queueItems, 'status', () => {
         return this.http({
            method: 'POST',
            url: '/api/ingestQueueItems',
            data: this._.map(queueItems, this._.partialRight(this._.pick, ['id', 'status'])),
            _rawData: true
         }).then(this.skeletonCache.unmarshallResults);
      });
   }

   public rankToTopQueueItemForEdgeServer(edgeServerId: string, queueItems: IngestQueueItem[]): Promise<any> {
      return this.rankToTop('/api/edgeServers/' + edgeServerId + '/ingestQueueItemRanks', queueItems);
   }

   public rankToTopQueueItemForArrivalsFolder(arrivalsFolderId: string, queueItems: IngestQueueItem[]): Promise<any> {
      return this.rankToTop('/api/arrivalsFolders/' + arrivalsFolderId + '/ingestQueueItemRanks', queueItems);
   }

   public fetchActionsOnEdgeServerQueue(edgeServerId: string): any {
      return this.cache.fetch('ingest-queue-actions-on-edge-server-' + edgeServerId, {
         url: '/api/edgeServers/' + edgeServerId + '/ingestQueueItemActions'
      });
   }

   public fetchActionsOnArrivalsFolderQueue(arrivalsFolderId: string): any {
      return this.cache.fetch('ingest-queue-actions-on-arrivals-folder-' + arrivalsFolderId, {
         url: '/api/arrivalsFolders/' + arrivalsFolderId + '/ingestQueueItemActions'
      });
   }

   public trashQueueItem(queueItem: IngestQueueItem): Promise<any> {
      queueItem.$$deleting = true;
      queueItem.error = null;
      return this.http({
         method: 'POST',
         url: '/api/ingestQueueItems/' + queueItem.id + '/trash'
      }).then(this.skeletonCache.unmarshall).then(() => {
         if (queueItem.error) {
            return this.$q.reject(queueItem.error);
         }
      }).finally(() => {
         queueItem.$$deleting = false;
      });
   }

   public trashQueueItems(queueItemsToTrash: IngestQueueItem[]): Promise<any> {
      this._.forEach(queueItemsToTrash, queueItem => {
         queueItem.$$deleting = true;
         queueItem.error = null;
      });
      return this.http({
         method: 'POST',
         url: '/api/ingestQueueItems/trash',
         data: this._.map(queueItemsToTrash, 'id'),
         _rawData: true
      }).then(this.skeletonCache.unmarshallResults).then(results => {
         let error = null;
         this._.forEach(results, queueItem => {
            if (queueItem.error) {
               error = queueItem.error;
            }
         });
         return error ? this.$q.reject(error) : results;
      }).finally(() => {
         this._.forEach(queueItemsToTrash, queueItem => {
            queueItem.$$deleting = false;
         });
      });
   }

   public queueItemMonitorUrl(queueItem: IngestQueueItem, width: number, height: number): string {
      return '/api/ingestQueueItems/' + queueItem.id +
         '/thumbnail?width=' + width + '&height=' + height;
   }

   public queueItemLogUrl(queueItem: IngestQueueItem): string | null {
      if (['failed', 'ingesting', 'ingested'].includes(queueItem.status)) {
         return '/api/ingestQueueItems/' + queueItem.id + '/log';
      }
      return null;
   }

   public filterToString(filter: Filter): string {
      return this._.keys(this._.pickBy(filter, this._.identity)).join('|');
   }

   public filterFromString(filterString): Filter {
      let filter = {
         actionRequired: true,
         duplicated: true,
         failed: true,
         missing: true,
         queued: true,
         ingesting: true,
         ingested: false,
         files: true,
         streams: true,
         unprocessed: false,
      };
      if (filterString) {
         filter = this._.mapValues(filter, this._.constant(false));
         this._.forEach(filterString.split('|'), name => {
            if (name in filter) {
               filter[name] = true;
            }
         });
      }
      return filter;
   }

   private fetchSince(url: string, sinceVersion: number): Promise<any> {
      return this.http({
         method: 'GET',
         url: url,
         params: { sinceVersion: sinceVersion },
         _rawData: true
      });
   }

   private queueItemPost(data: Partial<IngestQueueItem>): Promise<any> {
      return this.http({
         method: 'POST',
         url: '/api/ingestQueueItems/' + data.id,
         data: data
      }).then(result => {
         if (result.error) {
            return Promise.reject(result.error);
         } else {
            return this.skeletonCache.unmarshall(result);
         }
      });
   }

   private fetchAndSubscribe(url: string, asyncCacheKey: string, offset: string, sort: Sort, filterString: string, search: Search, handler, errorHandler): Promise<any> {
      return this.cache.fetch(asyncCacheKey, () => {
         const params: any = {};
         if (offset) {
            params.offset = offset;
         }
         if (sort) {
            params.sortKey = sort.key;
            params.sortDirection = sort.direction;
         }
         if (filterString) {
            params.filters = filterString;
         }
         if (search) {
            params.search = search.term;
         }
         return this.http({
            method: 'GET',
            url: url,
            params: params,
            _rawData: true
         }).then(this.skeletonCache.unmarshallResults);
      }).then(results => {
         handler(results);
         const updater = sinceVersion => {
            return this.fetchSince(url, sinceVersion).then(fetched => {
               fetched = this.skeletonCache.unmarshallResults(fetched);
               handler(fetched);
               return fetched.version;
            }, errorHandler);
         };
         this.apiUpdates.subscribeToIncrementalUpdates(updater, results.channel, results.version);
         return results;
      }, errorHandler);
   }

   private rankToTop(url: string, queueItems: IngestQueueItem[]): Promise<any> {
      this._.forEach(queueItems, queueItem => {
         queueItem.$$saving = true;
      });
      return this.http({
         method: 'POST',
         url: url,
         data: this._.map(queueItems, (queueItem, i) => {
            return {
               queueItemId: queueItem.id,
               rank: i + 1
            };
         }),
         _rawData: true
      }).then(this.skeletonCache.unmarshallResults).finally(() => {
         this._.forEach(queueItems, queueItem => {
            queueItem.$$saving = false;
         });
      });
   }
}

ngModule
   .service('IngestQueueItemsApi', IngestQueueItemsApi);

export default ngModule;
