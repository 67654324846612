import '../fbdn-angular-modules/http';
import '../utils/skeleton-cache.factory';
import '../utils/async-cache.factory';
import '../utils/api-updates.factory';
import EdgeServersApiModule from './edge-servers-api.factory';
import SaveTransactionModule from '../utils/save-transaction';
import { Schema } from '../dynamic-form/schema.interface';
import { StatefulObject } from '../utils/save-transaction/save-transaction.factory';
import { Service } from '../../edge-services/service.interface';

export interface EdgeService extends StatefulObject<EdgeService> {
   id: string;
   name: string;
   type: string;
   typeId: string;
   status: string;
   online: boolean;
   diskSpaceUsedProportion: number | null;
   allowsEndpoints: boolean;
   message: string | string[];
   bad: boolean;
   edgeServer: any;
   errored: boolean;
   options: { [key: string]: any };
   pendingSettings: boolean;
   settingsVersion: string;
   _channel: string;
}

interface ServiceType {
   id: string;
   name: string;
   description: string;
   schema: Schema;
}

const ngModule = angular.module('bb.api.services', [
   'bb.http',
   'bb.utils.async-cache',
   'bb.utils.skeleton-cache',
   'bb.utils.api-updates',
   EdgeServersApiModule.name,
   SaveTransactionModule.name,
]);
// provides services for edges, so the name ServicesApi is very misleading, and there is a new  services.api.service that does pure api's for services...
ngModule.factory('ServicesApi', ['$q', 'EdgeServersApi', 'SaveTransaction', 'AsyncCache', 'ApiUpdates', 'SkeletonCache', 'http', '_', 'DynamicSchema',
                                 function($q, EdgeServersApi, SaveTransaction, AsyncCache, ApiUpdates, SkeletonCache, http, _, DynamicSchema) {
   var lastTemporaryId = 0;
   var asyncCache = AsyncCache('ServicesApi');
   var skeletonCache = SkeletonCache('ServicesApi', {
      marshall: function(service) {
         return {
            id: service.id,
            name: service.name,
            typeId: service.typeId,
            edgeServerId: service.edgeServer.id,
            serviceGroupId: service.serviceGroupId,
            options: service.options
         };
      },
      unmarshall: function(service) {
         if (!service.edgeServer) {
           service.edgeServer = EdgeServersApi.getSkeleton(service.edgeServerId);
           delete service.edgeServerId;
         }
         return SaveTransaction.prepare(service, ['options']);
      }
   });
   var apiUpdates = ApiUpdates('ServicesApi', function(service) {
      fetch(service.id, true);
   });

   function fetchServiceTypesForEdgeServer(edgeServer) {
      return asyncCache.fetch('edge-server-service-types-' + edgeServer.id, {
         method: 'GET',
         url: '/api/edgeServers/' + edgeServer.id + '/serviceTypes'
      }).then(function(serviceTypes) {
         return _.keyBy(serviceTypes, 'id');
      });
   }

   function fetchServiceTemplatesForEdgeServer(edgeServer) {
      return asyncCache.fetch('edge-server-service-templates-' + edgeServer.id, {
         method: 'GET',
         url: '/api/edgeServers/' + edgeServer.id + '/serviceTemplates'
      }).then(function(serviceTemplates) {
         let n = 0;
         serviceTemplates.forEach(t => {
            // Flag this as a template, as we will have a menu with mixed service types and templates
            t.$$isTemplate = true;
            // Generate local ids for the templates, which do not come with ids naturally
            t.id = "template-"+n;
            n+=1;
         });
         return _.keyBy(serviceTemplates, 'id');
      });
   }

   function fetchServiceType(serviceTypeId) {
      return asyncCache.fetch('service-types-' + serviceTypeId, {
         method: 'GET',
         url: '/api/serviceTypes/' + serviceTypeId
      });
   }

   function fetchServiceTypeQueryResults(edgeServer, serviceType, queryName) {
      return http({
         method: 'GET',
         url: '/api/edgeServers/' + edgeServer.id + '/serviceTypes/' + serviceType.id + '/queries/' + queryName,
         _rawData: true
      });
   }

   function fetchServiceQueryResults(service, queryName, data) {
      var stringifiedJsonData = JSON.stringify(data || {});
      return asyncCache.fetch('service-query-' + service.id + '-' + queryName + '-' + stringifiedJsonData, {
         method: 'GET',
         url: '/api/services/' + service.id + '/queries/' + queryName,
         params: {
            data: stringifiedJsonData
         },
         _rawData: true
      });
   }

   function fetch(serviceId, forceFetch = false): Service {
      return asyncCache.fetch('service-' + serviceId, function() {
         return http({
            method: 'GET',
            url: '/api/services/' + serviceId
         }).then(skeletonCache.unmarshall);
      }, forceFetch).then(apiUpdates.subscribe);
   }

  function fetchForEdgeServer(edgeServer, forceFetch): IPromise<EdgeService[]> {
      return asyncCache.fetch('edge-server-' + (edgeServer.$$temporaryId || edgeServer.id), function() {
         return !edgeServer.id ? $q.when({results: []}) : http({
            method: 'GET',
            url: '/api/edgeServers/' + edgeServer.id + '/services',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, fetchForEdgeServer, [edgeServer, true]));
   }

   function getNewService(edgeServer, serviceTypeOrTemplate: any, serviceTypes: any) {

      if (serviceTypeOrTemplate.$$isTemplate) {
         const serviceType = serviceTypes[serviceTypeOrTemplate.typeId];
         return skeletonCache.unmarshall({
            ...serviceTypeOrTemplate,
            $$state: 'new',
            $$temporaryId: 'new-service-' + ++lastTemporaryId,
            id: null,
            options: DynamicSchema.defaultValuesForSchema(serviceType.schema, serviceTypeOrTemplate.options),
         });
      }

      return skeletonCache.unmarshall({
         $$state: 'new',
         $$temporaryId: 'new-service-' + ++lastTemporaryId,
         id: null,
         name: serviceTypeOrTemplate.name,
         description: serviceTypeOrTemplate.description,
         edgeServer: edgeServer,
         typeId: serviceTypeOrTemplate.id,
         options: DynamicSchema.defaultValuesForSchema(serviceTypeOrTemplate.schema),
      });
   }

   function createService(service) {
      service.pendingSettings = service.online;
      return SaveTransaction.saveObject(service, function() {
         return http({
            method: 'POST',
            url: '/api/edgeServers/' + service.edgeServer.id + '/services',
            data: skeletonCache.marshall(service)
         }).then(function(results) {
            service.id = results.id;
            skeletonCache.add(service);
            return results;
         }).then(skeletonCache.unmarshall).then(apiUpdates.subscribe);
      });
   }

   function saveService(service) {
      service.pendingSettings = service.online;
      return SaveTransaction.saveObject(service, function() {
         return http({
            method: 'POST',
            url: '/api/services/' + service.id,
            data: skeletonCache.marshall(service)
         }).then(skeletonCache.unmarshall);
      });
   }

   function deleteService(service, collectionToUpdate) {
      service.$$deleting = true;
      return (!service.id ? $q.when() : http({
         method: 'POST',
         url: '/api/services/' + service.id + '/trash'
      })).then(function(results) {
         if (collectionToUpdate) {
            _.pull(collectionToUpdate, service);
         }
         return results;
      }, function(error) {
         service.$$deleting = false;
         return $q.reject(error);
      });
   }

   function performAction(service, action, data) {
      return http({
         method: 'POST',
         url: '/api/services/' + service.id + '/actions/' + action,
         data: data || {}
      });
   }

   function saveName(service) {
      service.pendingSettings = service.online;
      return SaveTransaction.save(service, 'name', function(s, key) {
         return http({
            method: 'POST',
            url: '/api/services/' + s.id,
            data: {
               name: s[key]
            }
         }).then(skeletonCache.unmarshall);
      });
   }

   return {
      getSkeleton: skeletonCache.get,
      populateSkeleton: function(skeleton) {
         return fetch(skeleton.id);
      },
      fetchServiceTypesForEdgeServer: fetchServiceTypesForEdgeServer,
      fetchServiceTemplatesForEdgeServer: fetchServiceTemplatesForEdgeServer,
      fetch: fetch,
      fetchServiceType: fetchServiceType,
      fetchServiceTypeQueryResults: fetchServiceTypeQueryResults,
      fetchForEdgeServer: fetchForEdgeServer,
      fetchServiceQueryResults: fetchServiceQueryResults,
      unmarshall: skeletonCache.unmarshall,
      getNewService: getNewService,
      saveService: saveService,
      deleteService: deleteService,
      performAction: performAction,
      saveName: saveName,
      createService: createService
   };
}]);

export default ngModule;
