import UsersApiModule, { User } from '../api/users-api.factory';
import HttpModule from '../fbdn-angular-modules/http';
import PageFactoryModule from '../utils/page.factory';
import SkeletonCacheModule from '../utils/skeleton-cache.factory';
import AsyncCacheModule from '../utils/async-cache.factory';
import ApiUpdatesModule from '../utils/api-updates.factory';
import { EdgeServer } from '../../edge-servers/types';
import SaveTransactionModule, { SaveTransaction }  from '../utils/save-transaction';

const ngModule = angular.module('bb.api.edge-servers', [
   HttpModule.name,
   UsersApiModule.name,
   PageFactoryModule.name,
   SkeletonCacheModule.name,
   AsyncCacheModule.name,
   ApiUpdatesModule.name,
   SaveTransactionModule.name
]);

ngModule.factory('EdgeServersApi', ['$q', 'Page', 'http', 'UsersApi', '_', 'SaveTransaction', 'SkeletonCache', 'AsyncCache', 'ApiUpdates', 'DynamicSchema',
                                    function($q, Page: Page, http, UsersApi, _: Lodash, saveTransaction: SaveTransaction, SkeletonCache, AsyncCache, ApiUpdates, DynamicSchema) {
   var asyncCache = AsyncCache('EdgeServersApi');
   var skeletonCache = SkeletonCache('EdgeServersApi', {
      marshall: function(edgeServer) {
         return {
            id: edgeServer.id,
            userId: edgeServer.user.id,
            options: edgeServer.options,
            name: edgeServer.name
         };
      },
      unmarshall: function(edgeServer) {
         if (!edgeServer.user) {
            edgeServer.user = UsersApi.getSkeleton(edgeServer.userId);
            delete edgeServer.userId;
         }
         return saveTransaction.prepare(edgeServer, ['options']);
      }
   });

   function _populateSkeletons(edgeServer: EdgeServer & { user: User } ) {
      UsersApi.populateSkeleton(edgeServer.user);
      return edgeServer;
   }

   function fetch(edgeServerId: string, forceFetch: boolean): EdgeServer {
      return asyncCache.fetch('edge-server-' + edgeServerId, function() {
         return http({
            method: 'GET',
            url: '/api/edgeServers/' + edgeServerId
         }).then(skeletonCache.unmarshall);
      }, forceFetch).then(_populateSkeletons);
   }

   function connectToSupport(edgeServer: EdgeServer) {
      edgeServer.$$connecting = true;
      return http({
         method: 'POST',
         url: '/api/edgeServers/' + edgeServer.id + '/supportConnection'
      }).finally(function() {
         edgeServer.$$connecting = false;
      });
   }

   return {
      connectToSupport: connectToSupport,
      fetch: fetch,
      getSkeleton: skeletonCache.get,
      unmarshall: skeletonCache.unmarshall,
   };
}]);

export default ngModule;
