import '../utils/page.factory';
import '../utils/async-cache.factory';
import '../utils/api-updates.factory';
import '../utils/skeleton-cache.factory';
import '../fbdn-angular-modules/http';

import EdgeServersApiModule from './edge-servers-api.factory';
import ServicesApiModule from './services-api.factory';
import EndpointsApiModule from './endpoints.api.factory';
import DynamicFormModule from '../dynamic-form';
import UrlServiceModule, { UrlService } from '../utils/url.service';

const ngModule = angular.module('bb.api.status', [
   'bb.utils.page',
   'bb.utils.async-cache',
   'bb.utils.api-updates',
   'bb.utils.skeleton-cache',
   'bb.http',
   EdgeServersApiModule.name,
   ServicesApiModule.name,
   EndpointsApiModule.name,
   DynamicFormModule.name,
   UrlServiceModule.name,
]);

ngModule.factory('StatusApi', ['UrlService', 'Page', 'AsyncCache', 'ApiUpdates', 'SkeletonCache', 'EdgeServersApi', 'ServicesApi', 'EndpointsApi', 'http', '_',
                              function(urlService: UrlService, Page, AsyncCache, ApiUpdates, SkeletonCache, EdgeServersApi, ServicesApi, EndpointsApi, http, _) {
   var sets = {
      EdgeServer: {
         api: EdgeServersApi,
         getUrl: function(object) {
            return urlService.makeUrl('edge-server', {userId: object.user.id, edgeServerId: object.id});
         }
      },
      Service: {
         api: ServicesApi,
         getUrl: function(object) {
            return urlService.makeUrl('edge-server-services', {edgeServerId: object.edgeServer.id});
         }
      },
      Endpoint: {
         api: EndpointsApi,
         getUrl: function(object) {
            // Handle race condition of edgeServer not being property of endpoint's service yet
            const edgeServerId = object.service.edgeServer?.id || object.edgeServerId;
            return Page.context === 'site'
               ? urlService.makeUrl('edge-server-endpoints', {edgeServerId: edgeServerId, serviceId: object.service.id})
               : null;
         }
      }
   };

   function getApi(object) {
      return sets[object.type].api;
   }

   function getUrl(object) {
      return sets[object.type].getUrl(object);
   }

   var asyncCache = AsyncCache('StatusApi');

   var skeletonCache = SkeletonCache('StatusApi', {
      unmarshall: function(object) {
         return getApi(object).unmarshall(object);
      },
      collectionOnly: true
   });

   var apiUpdates = ApiUpdates('StatusApi', function(object) {
      getApi(object).fetch(object.id, true);
   });

   function fetchSiteStatus(siteId, forceFetch) {
      return asyncCache.fetch('site-status-' + siteId, function() {
         return http({
            method: 'GET',
            url: '/api/sites/' + siteId + '/status',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, fetchSiteStatus, [siteId, true]));
   }

   function fetchAccountStatus(accountId, forceFetch) {
      return asyncCache.fetch('account-status-' + accountId, function() {
         return http({
            method: 'GET',
            url: '/api/accounts/' + accountId + '/status',
            _rawData: true
         }).then(skeletonCache.unmarshallCollection);
      }, forceFetch).then(_.partialRight(apiUpdates.subscribeToCollection, fetchAccountStatus, [accountId, true]));
   }

   return {
      getUrl: getUrl,
      fetchSiteStatus: fetchSiteStatus,
      fetchAccountStatus: fetchAccountStatus
   };
}]);

export default ngModule;
