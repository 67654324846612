/*
   alternative to lodash
   function implementation
*/

export function difference(array, ...arrays) {
   const set = new Set(arrays.flat());
   return array.filter(item => !set.has(item));
}

export function removeFromArray(items, itemsToRemove) {
   itemsToRemove.forEach(item => {
      while (items.indexOf(item) !== -1) {
         items.splice(items.indexOf(item) , 1);
      }
   });
}

export function extend(target, ...sources) {
   for (const source of sources) {
      for (const key in source) {
         if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
         }
      }
   }
   return target;
}

export function cloneDeep(obj: any): any {
   if(typeof obj !== 'object' || obj === null) {
         return obj;
   }
   if(obj instanceof Date) {
         return new Date(obj.getTime());
   }
   if(obj instanceof Array) {
         return obj.reduce((arr, item, i) => {
            arr[i] = cloneDeep(item);
            return arr;
         }, []);
   }
   if(obj instanceof Object) {
         return Object.keys(obj).reduce((newObj, key) => {
            newObj[key] = cloneDeep(obj[key]);
            return newObj;
         }, {});
   }
}

export function equals(obj1, obj2): boolean {
   return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function identity(value: any): any {
   return value;
}

export function omitBy(object, predicate) {
   if (typeof predicate !== 'function') {
      throw new TypeError('Predicate must be a function');
   }
   const result = {};
   for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
         if (!predicate(object[key], key, object)) {
            result[key] = object[key];
         }
     }
   }
   return result;
 }

export function defaults(object, ...defaultsList) {
   if (object === null || typeof object !== 'object') {
      throw new TypeError('First argument must be an object');
   }
   for (const defaultsObject of defaultsList) {
      if (defaultsObject !== null && typeof defaultsObject === 'object') {
         for (const key in defaultsObject) {
            if (Object.prototype.hasOwnProperty.call(defaultsObject, key)) {
               if (object[key] === undefined) {
                  object[key] = defaultsObject[key];
               }
            }
         }
      }
   }
   return object;
}

export function isEmpty(value) {
   if (value === null) {
      return true;
   }
   if (typeof value === 'string' || Array.isArray(value)) {
      return value.length === 0;
   }
   if (typeof value === 'object') {
      return Object.keys(value).length === 0;
   }
   return false;
}

export function pickBy(object, predicate) {
   return Object.keys(object).reduce((result, key) => {
     if (predicate(object[key], key)) {
         result[key] = object[key];
      }
      return result;
   }, {});
}

export function clone(obj) {
   if (obj === null || typeof obj !== 'object') {
      return obj;
   }
   const newObj = Array.isArray(obj) ? [] : {};
   for (const key in obj) {
     if (obj.hasOwnProperty(key)) {
         newObj[key] = obj[key];
     }
   }
   return newObj;
}

export function lMap(array, iteratee) {
   const result = [];
   for (let i = 0; i < array.length; i++) {
      result.push(iteratee(array[i], i, array));
   }
   return result;
}

export function assign(target, ...sources) {
   for (const source of sources) {
     if (source) {
       for (const key in source) {
         if (source.hasOwnProperty(key)) {
            target[key] = source[key];
         }
       }
     }
   }
   return target;
}

export function deepEqual(obj1: any, obj2: any, matchLength=false): boolean {
   if (!obj1  && !obj2) {
      return true;
   }
   if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if(obj1 !== obj2) {
      }
      return obj1 === obj2;
   }
   const isArray = Array.isArray(obj1) && Array.isArray(obj2);
   if (isArray) {
     if (obj1.length !== obj2.length) {
       return false;
     }
     for (let i = 0; i < obj1.length; i++) {
         if (!deepEqual(obj1[i], obj2[i])) {
            return false;
         }
      }
      return true;
   }
   const keys1 = Object.keys(obj1);
   const keys2 = Object.keys(obj2);
   if(matchLength) {
         if (keys1.length !== keys2.length) {
         return false;
      }
   }
   for (const key of keys1) {
      if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
         return false;
      }
   }
   return true;
}

export function omit(obj, keysToOmit) {
   return Object.keys(obj).reduce((result, key) => {
      if (!keysToOmit.includes(key)) {
         result[key] = obj[key];
      }
      return result;
   }, {});
}

export function filterKeysStartingWith(obj: any, prefix: string): any {
   return Object.keys(obj).reduce((result: any, key: string) => {
      if (!key.startsWith(prefix)) {
         result[key] = obj[key];
      }
      return result;
   }, {});
}
