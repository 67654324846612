import AsyncCacheModule, { AsyncCache } from '../utils/async-cache.factory';
import HttpModule from '../fbdn-angular-modules/http';
import { LaunchModeDto, EntityBase, LaunchModes, LaunchModeName, LaunchMode } from '../types';

const ngModule = angular.module('bb.api.editor-data', [
   AsyncCacheModule.name,
   HttpModule.name,
]);

export interface EditorDataApi {
   fetchLaunchModes(transform?: boolean): Promise<LaunchModeDto[] | LaunchModes>;
   fetchSkins(): Promise<EntityBase[]>;
   fetchVersions(): Promise<EntityBase[]>;
   getDefaultLaunchMode(launchModeName: LaunchModeName): Promise<LaunchMode>;
}

ngModule.factory('EditorDataApi', ['AsyncCache', function(asyncCache: AsyncCache): EditorDataApi {
   const api: EditorDataApi = this;
   const cache = asyncCache('EditorDataApi');

   api.fetchLaunchModes = (transform=false) => {
      return cache.fetch<LaunchModeDto[]>('launch-modes', {
         method: 'GET',
         url: '/api/editor/launchModes',
      }).then(launchModes => transform ? mapFromDto(launchModes) : launchModes);
   };

   api.getDefaultLaunchMode = (launchModeName: LaunchModeName) => {
      return api.fetchLaunchModes(true)
         .then((launchModes: LaunchModes) => {
            const [defaultLaunchModeName, m] = Object.entries(launchModes)
               .find(([name, mode]) => mode.default) as [LaunchModeName, LaunchMode];
            return launchModeName in launchModes
               ? launchModes[launchModeName]
               : launchModes[defaultLaunchModeName];
         });
   };

   api.fetchSkins = () => {
      return cache.fetch('skins', {
         method: 'GET',
         url: '/api/editor/skins',
      });
   };

   api.fetchVersions = () => {
      return cache.fetch('versions', {
         method: 'GET',
         url: '/api/editor/versions',
      });
   };

   function mapFromDto(launchModes: LaunchModeDto[]): LaunchModes {
      return launchModes.map(mode => {
         const [action, maxheap] = mode.path.split('?');
         const [maxheapKey, maxheapValue] = maxheap.split('=');
         const launchFn = window.popupfull;
         return {
            name: mode.name,
            kind: mode.kind,
            label: mode.label,
            default: mode.default,
            params: { [maxheapKey]: maxheapValue },
            action,
            launchFn,
         };
      })
      .reduce((prev, curr) => {
         const { name, ...mode } = curr;
         return {
            ...prev,
            [name]: mode,
         };
      }, {} as LaunchModes);
   }

   return api;
}]);

export default ngModule;
