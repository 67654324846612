import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, combineLatest, ReplaySubject } from 'rxjs';
import { map, scan, switchMap, reduce, distinct, tap, catchError, distinctUntilChanged } from 'rxjs/operators';
import { FolderNode } from '../interfaces/folder-node.interface';
import { CombinedNode } from '../interfaces/combined-node.type';
import { NodeCard } from '../interfaces/node-card.interface';
import { NodeBreadcrumb } from '../interfaces/node-breadcrumb.interface';
import { NodesApiService } from './nodes-api.service';
import { NodeApiParams } from '../interfaces/node-api-params';
import { NodeSearchClause } from '../interfaces/node-search-clause';
import { SortOrder } from '../enums/sort-order.enum';
import { DisplayTypeCount } from '../interfaces/display-type-count.interface';
import { FileOperations } from '../interfaces/possible-transfers-type';
import { HttpErrorResponse } from '@angular/common/http';
import { NewChildrenCombinedNode } from '../interfaces/new-children-combined-node.type';
import { PublishDialogService } from './publish-dialog-service';
import { NodeDetails } from '../interfaces/node-details.interface';
import { StringKeyValue } from '../../../../shared/types';
import { AlertService } from '../../../../shared/services/alert.service';
import { ClipData } from '../interfaces/clip-data.interface';

@Injectable({
   providedIn: 'root'
})
export class NodesFlatTreeService {
   private nodesFlatTree = new BehaviorSubject<CombinedNode[]>([]);
   private searchClauses = new BehaviorSubject<NodeSearchClause[]>([]);
   private searchCreatedDate = new BehaviorSubject<number>(null);
   private childOrder = new BehaviorSubject<SortOrder>(SortOrder.Time);
   private childFilter = new BehaviorSubject<string>(null);
   private selectedPath = new BehaviorSubject<string>('');
   private displayTypes = new BehaviorSubject<string[]>([]);
   private searchLoading = new BehaviorSubject<boolean>(false);

   constructor(
      private nodesApiService: NodesApiService,
      private publishDialogService: PublishDialogService,
      private alertService: AlertService,
      @Inject('User') private User: User
   ) {
      this.displayTypes.next(['source', 'folder', 'sequence']);
      this.searchCreatedDate.next(null);
   }

   //Getter and Setters

   public getDisplayType(): string[] {
      return this.displayTypes.value;
   }

   public getDisplayTypesCount(): Observable<DisplayTypeCount> {
      return combineLatest([this.selectedPath, this.nodesFlatTree]).pipe(
         map(items => items[1].filter(node => node.parentPath === items[0])),
         switchMap(data => from(data).pipe(
            reduce(
            (acc, node) => (acc[node.displayType]=acc[node.displayType]+1, acc), {folder:0, source:0, sequence:0} as DisplayTypeCount)))
      );
   }

   public getNodesTree(): Observable<CombinedNode[]> {
      return this.nodesFlatTree;
   }

   public getChildOrder(): Observable<SortOrder> {
      return this.childOrder;
   }

   public updateChildOrder(newChildOrder: SortOrder) {
      this.childOrder.next(newChildOrder);
      // this.updateSelectedPath(this.selectedPath.value);
   }

   public getChildFilter(): Observable<string> {
      return this.childFilter;
   }

   public updateChildFilter(newChildFilter: string) {
      this.childFilter.next(newChildFilter);
   }

   public getSearchClauses(): Observable<NodeSearchClause[]> {
      return this.searchClauses;
   }

   public getSearchKey(): string {
      return this.nodesApiService.encodeSearchKey(this.searchClauses.value);
   }

   public updateSearchClauses(newSearchClauses: NodeSearchClause[], filterCreatedDate?: number) {
      this.startSearchLoading();
      this.searchCreatedDate.next(filterCreatedDate);
      this.searchClauses.next(newSearchClauses);
      this.updateSelectedPath(this.selectedPath.value);
   }

   public resetSearchClause() {
      this.searchClauses.next([]);
      this.searchCreatedDate.next(null);
      this.selectedPath.next(this.selectedPath.value);
      this.stopSearchLoading();
   }

   public getSelectedPath(): Observable<string> {
      return this.selectedPath;
   }

   public getSelectedPathValue(): string {
      return this.selectedPath.value;
   }

   public getNodeByPath(path: Observable<string>): Observable<CombinedNode> {
      return combineLatest([path, this.nodesFlatTree]).pipe(
         map(item => item[1].find(node => node.path === item[0])),
         distinctUntilChanged(),
      );
   }

   public getNodeDetailsBySelectedPath(): Observable<NodeDetails> {
      return combineLatest([this.selectedPath, this.nodesFlatTree]).pipe(
         map(item => item[1].find(node => node.path === item[0])),
      );
   }

   public getNodeDetailsByPath(path: Observable<string>): Observable<NodeDetails> {
      return combineLatest([path, this.nodesFlatTree]).pipe(
         map(item => item[1].find(node => node.path === item[0])),
      );
   }

   public isSearchLoading(): Observable<boolean> {
      return this.searchLoading;
   }

   public startSearchLoading() {
      this.searchLoading.next(true);
   }

   public stopSearchLoading() {
      this.searchLoading.next(false);
   }

   // Public Tree Methods
   public setAllPathNodesInPath(path: string) {
      of(path).pipe(
         switchMap(selectedPath => this.getActivePaths(selectedPath)),
         map(pathPart => this.updateSelectedPath(pathPart))
      ).subscribe();
   }

   public updateNodeDetailsByPathObservable(nodePath: Observable<string | boolean>) {
      nodePath.pipe(
         tap(path => this.updateNodeDetailsByPath(path))
      );
   }

   public updateNodeDetailsByPath(nodePath: string | boolean) {
      of(nodePath).pipe(
         switchMap(path => path ? this.fetchDetails(path as string) : of(new Array<CombinedNode>())),
         switchMap(data => this.getUniqueNodes([...data, ...this.nodesFlatTree.getValue()]) ),
         map(data => data.sort((a, b) => this.sortingNodes(a, b) ))
      ).subscribe(
         data => this.nodesFlatTree.next(data)
      );
   }

   public updateSelectedPathByNode(node: CombinedNode) {
      this.selectedPath.next(node.path);
      if (!node.loaded) {
         this.updateByPath(node.path);
      }
   }

   public updateSelectedPath(newSelectedPath: string) {
      this.selectedPath.next(newSelectedPath);
      this.updateByPath(newSelectedPath);
   }

   public updateByPath(path: string) {
      of(path).pipe(
         switchMap(selectedPath => this.findOrFetchNode(selectedPath)),
         map(data => this.setNodePathLoaded(data, path)),
         switchMap(data => this.getUniqueNodes([...data, ...this.nodesFlatTree.getValue()]) ),
         map(data => data.sort((a, b) => this.sortingNodes(a, b))),
      ).subscribe(
         data => {
            this.nodesFlatTree.next(data);
            this.stopSearchLoading();
         }
      );
   }

   public updateDisplayTypeSelection(newDisplayTypes: string[]){
      this.displayTypes.next(newDisplayTypes);
   }

   public getAllExpandableNodesWithoutLevel(level: number): Observable<FolderNode[]> {
      return this.getAllExpandableNodes().pipe(
         map(nodes => nodes.filter(node => node.level !== level))
      );
   }

   public getAllExpandableNodesInLevel(level: number): Observable<FolderNode[]> {
      return this.getAllExpandableNodes().pipe(
         map(nodes => nodes.filter(node => node.level === level))
      );
   }

   public getAllExpandableNodes(): Observable<FolderNode[]> {
      return this.nodesFlatTree.pipe(
         map(nodes => nodes.filter(node => node.expandable))
      ) as Observable<FolderNode[]>;
   }

   public getAllExpandableNodesPath(): Observable<string[]> {
      return this.nodesFlatTree.pipe(
         map(nodes => nodes.filter(node => node.parentId)),
         map(nodes => nodes.map(node => node.path)),
      ) as Observable<string[]>;
   }

   public getNodeCardsInSelectedPath(): Observable<NodeCard[]> {
      return combineLatest([this.selectedPath, this.nodesFlatTree, this.displayTypes, this.searchCreatedDate]).pipe(
         map(item => item[1].filter(node => node.parentPath === item[0])),
         map(items => items.filter(node => this.displayTypes.value.includes(node.displayType))),
         map(nodes => {
            if (this.searchCreatedDate.getValue()){
               return nodes.filter(node => node.createdTimeStamp > this.searchCreatedDate.getValue());
            }
            return nodes;
         })
      );
   }

   public getBreadcrumbs(): Observable<NodeBreadcrumb[]> {
      return this.selectedPath.pipe(
         switchMap(selectedPath => this.getActivePaths(selectedPath).pipe(
            reduce((acc, value) => acc.concat(value), [] as string[]),
         )),
         switchMap(selectedPaths => this.nodesFlatTree.pipe(
            map(nodes => nodes.filter(
               node => node.expandable && selectedPaths.indexOf(node.path) > -1).sort(
                  (a,b) => selectedPaths.indexOf(a.path) - selectedPaths.indexOf(b.path)
               )
            ),
         )),
      );
   }

   public getMetadataKeys(): Observable<string[]> {
      return this.nodesFlatTree.pipe(
         switchMap(nodes => this.getUniqueArtefactMetadata(nodes)),
      );
   }

   public getMetadataKeysByPath(path: string): Observable<string[]> {
      return this.nodesFlatTree.pipe(
         map(nodes => nodes.filter(node => node.path === path)),
         switchMap(nodes => nodes.map(node => node.artefactMetadata)),
         map(meta => meta.map(item => item.value)),
         map(nodes => [...nodes, ...this.nodesApiService.getDefaultAccumulateMetadataKeys()])
      );
   }

   public getMetaDataKeysInSlectedPath() {
      return this.getMetadataKeysByPath(this.selectedPath.value);
   }

   public getExpandableChildrenInPath(path: string): Observable<FolderNode[]> {
      return this.nodesFlatTree.pipe(
        map(nodes => nodes.filter(node => node.expandable)),
        map(nodes => nodes.filter(node => node.parentPath === path))
      ) as Observable<FolderNode[]>;
   }

   public updateParentPath(path: string[], newParentPath: string, operation) {
      return this.nodesApiService.asyncTransfer(path, newParentPath, operation, {}).pipe(
         catchError(err => this.processAdditionalPublishData(path, newParentPath, operation, {}, err)),
         switchMap(data => this.getUniqueNodes([...data?.newChildren, ...this.nodesFlatTree.getValue()]) ),
         map(nodes => nodes.filter(node => path.indexOf(node.path) === -1 || operation === 'copy')),
         map(data => data.sort((a, b) => this.sortingNodes(a, b) ))
      ).subscribe(
         data => {
            this.nodesFlatTree.next(data);
         }
      );
   }

   public updateToggleDetailsInPath(newSelectedPath: string, type: string = null) {
      of(newSelectedPath).pipe(
         switchMap(selectedPath => this.findOrFetchNode(selectedPath, null, type)),
         switchMap(data => this.getUniqueNodes([...data, ...this.nodesFlatTree.getValue()]) ),
         map(data => data.sort((a, b) => this.sortingNodes(a, b) ))
      ).subscribe(
         data => {
            // updating the nodeflatTree on only change in length. This function used only when toggle. Not recommendated for other purpose.
            if(this.nodesFlatTree.value.length !== data.length) {
               this.nodesFlatTree.next(data);
            }
         }
      );
   }

   public updateNextNodes() {
      if (this.nodesApiService.nextChildOffset) {
         of(this.nodesApiService.nextChildOffset).pipe(
            switchMap(nextChildOffset => this.findOrFetchNode(this.selectedPath.value, nextChildOffset)),
            switchMap(data => this.getUniqueNodes([...data, ...this.nodesFlatTree.getValue()]) ),
            map(data => data.sort((a, b) => this.sortingNodes(a, b) ))
         ).subscribe(
            data => this.nodesFlatTree.next(data)
         );
      }
   }

   public getPossibleOperations(path: string[], newParentPath: string): Observable<FileOperations[]>{
      return this.nodesApiService.fetchPossibleTransfers(path, newParentPath, {}).pipe(
         map(response => response.operations),
         this.alertService.notifyOnError("fetching file operations"),
      );
   }

   public addFolder(): ReplaySubject<FolderNode> {
      const newFolderNode = new ReplaySubject<FolderNode>();
      this.nodesApiService.createNode(this.selectedPath.value, {type: 'folder', name: 'New folder'}).subscribe(
         folder => {
            this.nodesFlatTree.next([...this.nodesFlatTree.getValue(), folder]);
            newFolderNode.next(folder as FolderNode);
            newFolderNode.complete();
         }
      );
      return newFolderNode;
   }

   public saveClipToFolderNode(targetPath: string, clip: ClipData): void {
      // When Drag and Drop Ready we will use targetPath, currently it is just using selected path.
      // This function is a placeholder as we do not have the data from react yet.
      if(targetPath === null) {
         targetPath = this.selectedPath.value;
      }
      const data = {
         type: 'edl',
         name: clip.name,
         thumb: { type: 'jpeg', data: clip.imageJpegDataURL.split(",")[1] },
         thumbPoint: (clip.imageFrame - (clip.inFrame | 0)) / clip.fps,
         edl: clip.edl
      };
      // Create node and Merge Returning Data
      this.nodesApiService.createNodeWithOutRetry(targetPath, data).pipe(
         catchError(err => this.processAdditionalPublishDataForCreateNode(targetPath, data, err)),
         switchMap(node => this.getUniqueNodes([...[node], ...this.nodesFlatTree.getValue()]) ),
         map(nodes => nodes.sort((a, b) => this.sortingNodes(a, b) ))
      ).subscribe(
         result => this.nodesFlatTree.next(result)
      );
   }

   public getChildrensByPath(path: string): Observable<CombinedNode[]> {
      return this.nodesFlatTree.pipe(
         map(nodes => nodes.filter(node => node.parentPath === path)),
      );
   }

   public updateFolderPermissions(node: FolderNode) {
      this.nodesApiService.saveFolderPermissions(node.path, node.permissions).pipe(
         switchMap(data => this.getUniqueNodes(
            [...this.nodesFlatTree.getValue(),...data]
         )),
         map(data => data.sort((a, b) => this.sortingNodes(a, b) ))
      ).subscribe(
         data => this.nodesFlatTree.next(data)
      );
   }

   public updateDetails(node: CombinedNode) {
      of(node).pipe(
         switchMap(data => this.getUniqueNodes(
            [...[data], ...this.nodesFlatTree.getValue()]
         )),
         map(data => data.sort((a, b) => this.sortingNodes(a, b) ))
      ).subscribe(
         data => this.nodesFlatTree.next(data)
      );
   }

   public saveDetails(path: string, accountId: string, changes: StringKeyValue) {
      const data: any = {};
      data[changes.key] = changes.value;
      data.accountId = accountId;
      data.path = path;
      this.nodesApiService.updateNode(data).pipe(
         switchMap(_ => path ? this.fetchDetails(path) : of(new Array<CombinedNode>())),
         switchMap(items => this.getUniqueNodes(
            [...items, ...this.nodesFlatTree.getValue()]
         )),
         map(items => items.sort((a, b) => this.sortingNodes(a, b) )),
      ).subscribe(
            nodes => this.nodesFlatTree.next(nodes)
      );
   }

   public saveMediaDetails(mediaId: string, accountId: string, changes: StringKeyValue) {
      const data: any = {};
      data[changes.key] = changes.value;
      data.accountId = accountId;
      this.nodesApiService.saveMedia(mediaId, data).subscribe();
   }

   public saveMediaDataDetails(mediaId: string, accountId: string, path: string, data: StringKeyValue[]) {
      this.nodesApiService.saveMediaData(mediaId, accountId, path, data).subscribe();
   }

   //Observable Helpers

   private processAdditionalPublishData(srcPath: string[], dstPath: string, operation: string, params: any, err: HttpErrorResponse): Observable<NewChildrenCombinedNode> {
      if (err.error?.errorData && err?.error?.error === "notEnoughPublishData") {
         err.error?.errorData.forEach(element => {
            this.publishDialogService.openPublishDialog(element).pipe(
               switchMap(data => {
                  if (data) {
                     const userPublishData = {};
                     userPublishData[element.nodeId] = data;
                     const retVal = this.nodesApiService.asyncTransfer(srcPath, dstPath, operation, params, userPublishData);
                     this.alertService.show({
                        type: 'info',
                        text: "Node " + srcPath +" is "+ operation  +" to "+ dstPath +" successfully!"
                     });
                     return retVal;
                  }
                  return of({} as NewChildrenCombinedNode);
               })
            ).subscribe(
               data => {return data;}
            );
         });
      } else if (err?.message) {
         this.alertService.show({
            type:'warning',
            text: err?.message
         });
      }
      this.nodesApiService.handleError(err);
      return of({} as NewChildrenCombinedNode);
   }

   private processAdditionalPublishDataForCreateNode(targetPath: string, data: any, err: HttpErrorResponse): Observable<CombinedNode>{
      if (err?.error && err?.error?.errorData && err?.error?.error === "notEnoughPublishData") {
         err.error.errorData.forEach(element => {
            this.publishDialogService.openPublishDialog(element).subscribe(
               inputData => {
                  if (inputData) {
                     data.userPublishData = {};
                     data.userPublishData[element.nodeId] = inputData;
                     this.nodesApiService.createNodeWithOutRetry(targetPath, data).pipe(
                        this.alertService.notifyOnError(),
                        switchMap(node => this.getUniqueNodes([...[node], ...this.nodesFlatTree.getValue()]) ),
                        map(nodes => nodes.sort((a, b) => this.sortingNodes(a, b) ))
                     ).subscribe(
                        result => this.nodesFlatTree.next(result)
                     );
                     this.alertService.show({
                        type: 'info',
                        text: "Published on "+ targetPath +" successfully!"
                     });
                  }
               }
            );
         });
      } else if (err?.message) {
         this.alertService.show({
            type:'warning',
            text: err?.message
         });
      }
      this.nodesApiService.handleError(err);
      return of({} as CombinedNode);
   }

   private sortingNodes(a: CombinedNode, b: CombinedNode): number {
      if(a.sortKey !== b.sortKey) {
         return a.sortKey - b.sortKey;
      }
      if(a.name !== b.name) {
         return a.name < b.name ? -1 : 1;
      }
      return a.id < b.id ? -1 : 1;
   }

   private getUniqueNodes(nodes: CombinedNode[]): Observable<CombinedNode[]> {
      return from(nodes).pipe(
         distinct(node => node.path),
         reduce((acc, value) => acc.concat(value), [])
      );
   }

   private getUniqueArtefactMetadata(nodes: CombinedNode[]): Observable<string[]> {
      return from(nodes).pipe(
         distinct(node => node.artefactMetadata),
         map(node => node.artefactMetadata),
         reduce((acc, value) => acc.concat(value), [])
      );
   }

   private getActivePaths(path: string): Observable<string> {
      return of(path).pipe(
         switchMap(selectedPath => from(this.nodesApiService.explodePath(selectedPath)).pipe(
            scan((paths, pathPart) => paths ? paths += '/' + pathPart : paths += pathPart, ''),
         ))
      );
   }

   private findOrFetchNode(path: string, nextChildOffset: string = null, type: string = null): Observable<CombinedNode[]> {
      const params: NodeApiParams = {
         path,
         childOrder: this.childOrder.value
      };
      if (type) {
         params.type = type;
      }
      if (nextChildOffset) {
         params.nextChildOffset = nextChildOffset;
      }

      params.searchClauses = this.searchClauses.value;

      if(this.childFilter.value) {
         params.childFilter = this.childFilter.value;
      }

      return this.nodesApiService.fetchNode(params);
   }

   private getParentPathByPath(path: string): string {
      return this.nodesFlatTree.value.find(node => node.path === path).parentPath;
   }

   private fetchDetails(path: string): Observable<CombinedNode[]> {
      const parentPath = this.getParentPathByPath(path);
      const params = {
         path: path,
         id: null,
         userId: this.User.id,
         searchClauses: this.searchClauses.value,
         getDetails: true
      };
      return this.nodesApiService.fetchNodeDetails(params).pipe(
         map(node => {
            node.parentPath = parentPath;
            return node;
         }),
         switchMap((node) => {
            if(node.type === 'media') {
               return this.nodesApiService.fetchMediaContent(node.mediaId, node.accountId).pipe(
                  map((mediaContent) => {
                     node.mediaContent = mediaContent;
                     return node;
                  })
               );
            } else {
               return of(node);
            }
         }),
         switchMap((node) => {
            if(node.type === 'edl') {
                return combineLatest([
                    this.nodesApiService.fetchEdlSources(node.edl.id, node.accountId),
                    this.nodesApiService.fetchEdlPreviousVersions(node.edl.id, node.path),
                    this.nodesApiService.fetchEdlContent(node.edl.id, node.accountId)
                ]).pipe(
                    map(([sources,previous,content]) => {
                        node.edlSources = sources;
                        node.edlPreviousVersions = previous;
                        node.edlContent = content;
                        return node;
                    })
                );
            } else {
                return of(node);
            }
        }),
         map(node => [node])
      );
   }

   private setNodePathLoaded(nodes: CombinedNode[], path): CombinedNode[] {
      const nodeUpdate = nodes.filter(node => node.path === path);
      if (nodeUpdate.length) {
         nodeUpdate[0].loaded = true;
      }
      return nodes;
   }

}
