import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AsyncCacheService } from '../utils/async-cache.service';


export interface EditorInfo {
   id: string;
   name: string;
}

@Injectable({
   providedIn: 'root',
})
export class EditorDataApiService {
   private asyncCache: any;

   constructor(
      private http: HttpClient,
      private asyncCacheService: AsyncCacheService<EditorInfo>,
   ) {
      this.asyncCache = this.asyncCacheService.initAsyncCache("EditorDataApiService");
   }

   getEditorSkins(forceFetch=false): Observable<EditorInfo[]> {
      const path = '/api/editor/skins';
      const getSkins$ = this.http.get<EditorInfo[]>(path).pipe(shareReplay(1));
      return this.asyncCache.fetch(path, getSkins$, forceFetch);
   }

   getEditorVersions(forceFetch=false): Observable<EditorInfo[]> {
      const path = '/api/editor/versions';
      const getVersions$ = this.http.get<EditorInfo[]>(path).pipe(shareReplay(1));
      return this.asyncCache.fetch(path, getVersions$, forceFetch);
   }
}
