<div class="form-group publish-server" *ngIf="button.location || reportLength(invalidReport)">
   <label class="control-label">Where to run</label>
   <div class="form-control-group {{reportLength(invalidReport) ? 'has-error' : ''}}">
      <select
         *ngIf="button.location"
         (ngModelChange)="locationChange($event)"
         [ngModel]="getSelectedLocation()"
         name="location"
         class="form-control"
      >
         <option *ngFor="let location of selectableLocations" [ngValue]="location">
            {{location.name}}
         </option>
      </select>
      <p *ngIf="reportLength(invalidReport)" class="help-block">
         <em>{{button.location.name}}</em> will not be able to run this publishing button because
         <span class="help-block-list">
            <span *ngFor="let item of invalidReport; trackBy: index | keyvalue">
               <span *ngIf="item.error === 'unknown-location'">
                  the location is not available
               </span>
               <span *ngIf="item.error !== 'unknown-location'">
                  the module <em>{{modules[item.moduleId].name}}</em> {{item.error === 'needs-upgrade' ? 'must be upgraded' : 'is not installed'}}
               </span>
            </span>
         </span>.
      </p>
   </div>
</div>
