import { Component, OnInit, Input, Inject, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Subject, forkJoin, Observable, of, combineLatest} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, Validators, FormArray } from '@angular/forms';


import { AccountUsersApiService } from '../../shared/services/account-users.api.service';
import { EditorDataApiService, EditorInfo } from '../../shared/services/editor-data.api.service';
import { UsersApiService } from '../../shared/api/users.api.service';
import { UrlService } from '../../shared/utils/url.service';
import { Role, UserSummary } from '../../shared/types';
import { ParentChildInvalidMatcher, SimpleErrorStateMatcher } from '../../shared/utils/forms/parent-child-invalid-matcher';
import { PermissionsChecker } from '../../shared/utils/permissions-checker';
import { Pagination } from '../../shared/components/api-paginator/pagination.interface';
import { AlertService } from '../../shared/services/alert.service';
import { PageService } from '../../shared/services/page.service';

type Context = 'Access' | 'Invites' | 'Sessions';
interface PagInt { [id: string]: { offset: number; limit: number }};

@Component({
   selector: 'bb-edit-account-users',
   templateUrl: './edit-account-users.component.html',
   styleUrls: ['./edit-account-users.component.scss',],
})
export class EditAccountUsersComponent implements OnInit, OnDestroy {
   @Input() context: Context;
   @ViewChild(MatTable) columnsTable: MatTable<UserSummary[]>;

   public displayedColumns: string[];
   public selectableRoles: Role[];
   public dataSource: UserSummary[] = [];
   public updatedAccessIdx: number[] = [];
   public deletedAccessIdx: number[] = [];
   public editorSkins: EditorInfo[] = [];
   public editorVersions: EditorInfo[] = [];
   public pageReady = false;
   public disconnectMessageForm: FormControl;
   public matcherFA = new ParentChildInvalidMatcher();
   public matcherFC = new SimpleErrorStateMatcher();
   public sharedDisconnectMessage: string;
   public pagination: PagInt = {};

   private destroy$ = new Subject<void>();
   private jobsToDo: string[] = [];
   private page: Page;


   constructor(
      private alertService: AlertService,
      private pageService: PageService,
      @Inject('UrlService') public urlService: UrlService,
      private accountUsersApiService: AccountUsersApiService,
      private editorDataApiService: EditorDataApiService,
      private userApiService: UsersApiService,
   ) {
      const contextValues: Context[] = ['Access', 'Invites', 'Sessions'];
      contextValues.forEach(cntx => this.pagination[cntx] = { offset: 0, limit: 100});
      this.sharedDisconnectMessage = 'Session ended by administrator, please relaunch or log in again';
      this.page = this.pageService.page;
   }


   ngOnInit() {
      this.reset();
   }

   ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
   }

   public usersInTable(): boolean {
      const activeUsers = this.dataSource.filter(user => !user.deleted);
      return activeUsers.length > 0;
   }

   public markAsDeleted(atIndex: number): void {
      if (this.jobsToDo.indexOf('deleted') === -1 ) {
         this.jobsToDo.push('deleted');
      }
      if ( this.dataSource.length > atIndex ) {
         this.dataSource[atIndex].deleted = true;
         if ( this.updatedAccessIdx.indexOf(atIndex) !== -1 ) {
            const updIdx = this.updatedAccessIdx.indexOf(atIndex, 0);
            if ( updIdx > -1 ) {
               this.updatedAccessIdx.splice(updIdx, 1);
            }
         }
         if ( this.deletedAccessIdx.indexOf(atIndex) === -1 ) {
            this.deletedAccessIdx.push(atIndex);
         }
      }
   }

   public userChanged(atIndex: number): void {
      if (this.jobsToDo.indexOf('updated') === -1 ) {
         this.jobsToDo.push('updated');
      }
      if ( this.updatedAccessIdx.indexOf(atIndex) === -1 ) {
         this.updatedAccessIdx.push(atIndex);
      }
   }

   public save(): void {
      const requestList = [];
      if ( this.deletedAccessIdx.length ) {
         requestList.push(
            ...this.deletedAccessIdx.map(idx => {
               switch(this.context) {
                  case 'Access': {
                     return this.accountUsersApiService.deleteUserAccess(this.page.accountId, this.dataSource[idx].id);
                  }
                  case 'Invites': {
                     return this.accountUsersApiService.deleteUserInvite(this.page.accountId, this.dataSource[idx].id);
                  }
                  case 'Sessions': {
                     return this.accountUsersApiService.deleteUserSession(this.page.accountId, this.dataSource[idx].id, this.sharedDisconnectMessage);
                  }
               }
            })
         );
      };
      if ( this.updatedAccessIdx.length ) {
         requestList.push(
            ...this.updatedAccessIdx.map(idx => {
                  if (this.context === 'Access') {
                     if (this.dataSource[idx].version === 'byAccount') {
                        this.dataSource[idx].version = null;
                     }
                     if (this.dataSource[idx].skin === 'byAccount') {
                        this.dataSource[idx].skin = null;
                     }
               }
               if (this.context === 'Access') {
                  return this.accountUsersApiService.updateUserAccess(this.page.accountId, this.dataSource[idx]);
               }
               else {
                  return this.accountUsersApiService.updateUserInvite(this.page.accountId, this.dataSource[idx]);
               }
            })
         );
      };
      const context = this.jobsToDo.join(', ') + ((this.context === 'Access') ? ' user access': ((this.context === 'Invites') ? ' invited users': 'disconnected users'));
      const notification = this.context === 'Sessions' ? 'Disconnection successful' : 'Changes saved';
      forkJoin(requestList)
            .pipe(
               this.alertService.notifyOnError(context),
               takeUntil(this.destroy$),
            )
            .subscribe(() => {
               this.alertService.show({
                  text: notification,
                  type: 'success',
               });
               this.reset();
            });
   }

   public emailString(emailAddress: string): string {
      const emailString='mailto:' + emailAddress;
      return emailString;
   }

   public discardChanges(): void {
      this.dataSource.splice(0);
      this.reset();
   }

   public noUsersAvailable(): string {
      switch(this.context) {
         case 'Sessions': {
            return 'No active user sessions';
         }
         case 'Access': {
            return 'No users with access to this account';
         }
         case 'Invites': {
            return 'No users invited to access this account';
         }
      }
   }

   public userEditUrl(userId: string): string {
      return this.urlService.makeUrl('user', { userId: userId });
   }

   public updateSharedDisconnectMessage(): void {
      this.sharedDisconnectMessage = this.disconnectMessageForm.value;
   }

   public isDisabled(index: number): boolean {
      return this.dataSource[index].deleted;
   }

   public disabledWhenInvalid(i: number): boolean {
      return (this.isDisabled(i) || (this.context === 'Sessions' && !this.dataSource[i].disconnectmessage?.length));
   }

   public markAllChecked(): void {
      this.dataSource.forEach(elem => elem.deleted = !elem.deleted);
      this.deletedAccessIdx = this.dataSource[0].deleted ? Array.apply(null, {length: this.dataSource.length}).map(Number.call, Number) : [];
      this.refresh();
   }

   public checkRow(rowStatus: boolean, index: number): void {
      this.dataSource[index].deleted = rowStatus;
      const indexOf = this.deletedAccessIdx.indexOf(index);
      if ( rowStatus && (indexOf === -1)) {
         this.deletedAccessIdx.push(index);
      }
      if ( !rowStatus && (indexOf !== -1)) {
         this.deletedAccessIdx.splice(indexOf,1);
      }
      this.refresh();
   }

   public sessionsSharedDisconnectMessageEmpty(): boolean {
      return !this.sharedDisconnectMessage.length;
   }

   public onPaginationChange(event: Pagination){
      this.pagination[this.context].offset = Number.isNaN(event.startIndex) ? this.pagination[this.context].offset: event.startIndex;
      this.pagination[this.context].limit = Number.isNaN(event.pageSize) ? this.pagination[this.context].limit: event.pageSize;
      this.reset();
   }

   public getPagedDataSource(): UserSummary[] {
      const startIndex = this.pagination[this.context].offset;
      const endIndex = (this.dataSource.length > this.pagination[this.context].offset + this.pagination[this.context].limit) ? this.pagination[this.context].offset + this.pagination[this.context].limit : this.dataSource.length;
      return this.dataSource.slice(startIndex, endIndex);
   }

   private refresh(): void {
      this.columnsTable?.renderRows();
   }

   private reset() {
      this.userApiService.fetchMe()
         .pipe(
            this.alertService.notifyOnError('loading users'),
            takeUntil(this.destroy$),
         )
         .subscribe((me) => {
            const isAdmin = PermissionsChecker.isAdmin(me);
            const offset = (this.pagination[this.context] === undefined) ? this.pagination.Access.offset: this.pagination[this.context]?.offset;
            const limit = (this.pagination[this.context] === undefined) ? this.pagination.Access.limit: this.pagination[this.context]?.limit;
            const getAccounts$: Observable<UserSummary[]> = (this.context === 'Access') ?
                  this.accountUsersApiService.getAccountUsers(this.page.accountId, true) :
                  (this.context === 'Invites') ? this.accountUsersApiService.getAccountInvites(this.page.accountId) :
                  this.accountUsersApiService.getAccountSessions(this.page.accountId, true);
            const getRoles$: Observable<Role[]> = this.accountUsersApiService.getAccountRoles(this.page.siteId);
            combineLatest([getAccounts$, getRoles$,
               ...( isAdmin ) && [
                  this.editorDataApiService.getEditorVersions(),
                  this.editorDataApiService.getEditorSkins(),
               ] || []
            ])
               .pipe(
                  this.alertService.notifyOnError('loading users'),
                  takeUntil(this.destroy$),
               )
               .subscribe(([accountData, roles, versions, skins]: any) => {
                  this.deletedAccessIdx = [];
                  this.updatedAccessIdx = [];
                  this.selectableRoles = roles.filter(role => role.isManager === false);
                  const selectableRoleIds = this.selectableRoles.map(a => a.id);
                  this.dataSource = accountData.map(x => Object.assign({}, x));
                  if (this.context === 'Access') {
                     this.dataSource = this.dataSource.filter(user => selectableRoleIds.includes(user.roleId));
                  }
                  this.dataSource.forEach(user => { // to select skin/version by account the column  in useraccess table has to be left null
                     if ( user.skin === null ) {     // as null is not a valid value to be selected, the string 'byAccount' is mapped to the null value
                        user.skin = 'byAccount';
                     }
                     if ( user.version === null ) {
                        user.version = 'byAccount';
                     }
                     user.deleted = false;
                  });
                  if ( isAdmin ) {
                     this.editorSkins = skins.map(x => Object.assign({}, x));;
                     this.editorSkins.push({id: 'byAccount', name: 'By Account'});
                     this.editorVersions = versions.map(x => Object.assign({}, x));;
                     this.editorVersions.push({id: 'byAccount', name: 'By Account'});
                  }
                  if ( this.context === 'Access' ) {
                     this.displayedColumns = ['select', 'username', 'fullname', 'email', 'role'];
                     if ( isAdmin ) {
                        this.displayedColumns = this.displayedColumns.concat(['version', 'skin']);
                     }
                  }
                  else if ( this.context === 'Invites' ) {
                     this.displayedColumns = ['select', 'email', 'role'];
                  }
                  else { // Sessions
                     this.displayedColumns = ['select', 'username', 'ip', 'status', 'from'];
                     this.disconnectMessageForm = new FormControl('', [Validators.required]);
                     this.disconnectMessageForm.setValue(this.sharedDisconnectMessage);
                  }
                  this.displayedColumns = this.displayedColumns.concat('delete');
                  this.pageReady = true;
                  this.refresh();
               });
         });
   }
}

