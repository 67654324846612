import { forEach } from 'lodash';

import { Schema, SchemaItem } from '../schema.interface';

type Values = {
   [key in keyof Schema]: any;
};

export class DynamicSchema {
   public static defaultValuesForSchema(schema: Schema, values?: Values): Values {
      values = values || {};
      forEach(schema, function(schemaItem, schemaItemName) {
         if (!values[schemaItemName]
            || (schemaItem.allowedValues && schemaItem.allowedValues.indexOf(values[schemaItemName].value) === -1)
         ) {
            values[schemaItemName] = {
               value: DynamicSchema._getDefaultValue(schemaItem)
            };
         }
      });
      return values;
   }

   private static _getDefaultValue(schemaItem: SchemaItem) {
      if ('defaultValue' in schemaItem) {
         return schemaItem.defaultValue;
      } else {
         /* eslint-disable id-blacklist */
         return {
            text: '',
            textarea: '',
            emailAddress: '',
            integer: 0,
            number: 0,
            fractionOrInteger: 0,
            boolean: false
         }[schemaItem.type];
      }
   }
}
