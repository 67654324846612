import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatToolbar } from '@angular/material/toolbar';
import { Subject, Subscription } from 'rxjs';
import { AutocompleteOptionGroup, FormAutocompleteOptions } from '../../components/autocomplete-item/autocomplete-option-group.interface';
import { keyInsertionOrder } from '../../filters/key-insertion-order';
import { Schema, SchemaItem } from '../schema.interface';
import { SubmitConfig } from '../submit-config.interface';
import { FormValues, PanelsOpen } from './types';
import { SelectableOption } from '../../dynamic-form/form.interface';
import { finalize, takeUntil } from 'rxjs/operators';
import { ErrorMessageService } from '../../services/error-message.service';
import { AlertService } from '../../services/alert.service';

interface ServerValidationErrors {
   [key: string]: {
      message: string | null;
   };
}

@Component({
   selector: 'bb-dynamic-form',
   templateUrl: './dynamic-form.component.html',
   styleUrls: ['./dynamic-form.component.scss'],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class DynamicFormComponent<T> implements OnChanges, OnDestroy {
   @ViewChild(MatToolbar) submitToolbar: MatToolbar;

   @Input() filteredFormOptions$: FormAutocompleteOptions;
   @Input() panelsOpen: PanelsOpen;
   @Input() form: FormGroup;
   @Input() schema: Schema<T>;
   @Input() submitConfig: SubmitConfig;
   @Output() formChanged = new EventEmitter<T>();
   @Output() panelOpenChanged = new EventEmitter<PanelsOpen>();

   public submitting = false;
   public serverErrors: ServerValidationErrors = {};
   public keyInsertionOrder = keyInsertionOrder;

   private subscription: Subscription;
   private ngDestroy$ =  new Subject();

   constructor(
      private alertService: AlertService,
      private errorMessageService: ErrorMessageService,
   ) {}

   ngOnChanges() {
      // If we don't unsubscribe every onChange, this will leak and
      // keep active subscriptions when changing forms e.g. via tabs
      this.subscription?.unsubscribe();
      this.subscription = this.form.valueChanges
         .subscribe((values: T) => {
            // Where parent does the submitting, emit up on change
            if (!this.submitConfig) {
               this.formChanged.emit(values);
            }
         });
   }

   ngOnDestroy() {
      this.subscription.unsubscribe();
      this.ngDestroy$.next(true);
      this.ngDestroy$.complete();
   }

   public hasError(fieldName: string, error: string): boolean {
      return this.form.get(fieldName).hasError(error);
   }

   public getErrorMessage(ctlName: string, schemaItem: SchemaItem) {
      if (this.hasError(ctlName, 'email')) {
         return 'Please enter a valid email address';
      } else if (this.hasError(ctlName, 'required')) {
         return 'This field cannot be empty';
      } else if (this.hasError(ctlName, 'minlength')) {
         return `Please enter at least ${schemaItem.minLength} characters`;
      } else if (this.hasError(ctlName, 'maxlength')) {
         return 'Character limit exceeded';
      } else if (this.hasError(ctlName, 'server') && this.serverErrors[ctlName]?.message) {
         return this.serverErrors[ctlName].message;
      } else if(this.hasError(ctlName, 'min') || this.hasError(ctlName, 'max')) {
         return "Invalid Value";
      }
   }

   public formValueUpdate(value: string, ctlName: string) {
      this.form.get(ctlName).patchValue(value);
   }

   public getFieldType(item: SchemaItem): string {
      if (item.valueLabels) return 'select';
      if (item.type === 'typeahead-dir') return 'typeahead-dir';
      if (item.type === 'autocomplete') return 'autocomplete';
      if (item.type === 'emailAddress') return 'email';
      if (item.type === 'text' || item.type === 'fractionOrInteger') return 'text';
      if (item.type === 'url') return 'text';
      if (item.type === 'integer') return 'number';
      if (item.type === 'number') return 'number';
      if (item.type === 'textarea') return 'textarea';
      if (item.type === 'toggle') return 'toggle';
      if (item.type === 'boolean') return 'checkbox';
      if (item.type === 'group') return 'group';
      if (item.type === 'password') return 'password';
      if (item.type === 'button') return 'button';
      if (item.type === 'select-combo') return 'select-combo';

      throw new Error(`${item.type} has not been implemented`);
   }

   public characterCounter(fieldName: string): string {
      const schemaItem: SchemaItem = this.schema[fieldName];
      const value = this.form.get(fieldName).value;
      if (!value) return;
      const len = this.getFieldLength(value, schemaItem);
      return len / schemaItem.maxLength >= 0.8
         ? `${len} / ${schemaItem.maxLength}`
         : '';
   }

   public expansionPanelOpened(ctlName: string, open = true): void {
      this.panelOpenChanged.emit({ [ctlName]: open });
   }

   public isExpansionPanelOpen(ctlName: string): boolean {
      return this.panelsOpen && this.panelsOpen[ctlName];
   }

   public submitForm(): void {
      this.submitting = true;
      this.serverErrors = {};

      const data: FormValues = this.form.value;
      Object.entries(data).forEach(([k, v]) => {
         if (typeof v !== 'object' || v === null) return;
         if ('value' in v) {
            // Select fields use their SelectableOption object as the value
            // so null can be used as a value (instead of it meaning deselected).
            // Extract value from the SelectableOption
            data[k] = v.value;
         }
      });
      if ( this.submitConfig ) {
         const submitObject = this.submitConfig.onSubmit(data);
         if (typeof submitObject?.then === 'function') {
            submitObject.then(() => {
               this.alertService.show({
                  text: `${this.submitConfig.formName || 'Data'} has been saved successfully`,
                  type: 'success',
               });
               this.form.markAsPristine();
            })
            .catch(error => {
               const erroredField = error.data?.errorData?.field;
               if (erroredField) {
                  this.serverErrors[erroredField] = { message: error.message };
                  this.form.get(erroredField).setErrors({server: true});
               } else {
                  // Only show notification if not adding the error message to the errored field
                  this.alertService.show({
                     text: 'An error occurred while saving - ' + this.errorMessageService.errorMessage(error),
                     type: 'danger',
                  });
               }
            })
            .finally(() => {
               this.submitting = false;
            });
         } else {
            submitObject
               .pipe(
                  takeUntil(this.ngDestroy$),
                  finalize(() => {
                     this.submitting = false;
                  }),
               )
               .subscribe(
               () => {
                  this.alertService.show({
                     text: `${this.submitConfig.formName || 'Data'} has been saved successfully`,
                     type: 'success',
                  });
                  this.form.markAsPristine();
               },
               error => {
                  const erroredField = error.data?.errorData?.field;
                  if (erroredField) {
                     this.serverErrors[erroredField] = { message: error.message };
                     this.form.get(erroredField).setErrors({server: true});
                  } else {
                     // Only show notification if not adding the error message to the errored field
                     this.alertService.show({
                        text: 'An error occurred while saving - ' + this.errorMessageService.errorMessage(error),
                        type: 'danger',
                     });
                  }
            });
         }
      }
   }

   private filterGroup(suggestion: AutocompleteOptionGroup, value: string): AutocompleteOptionGroup {
      value = value.toLowerCase();
      const filteredChildren = suggestion.children
         .map(child => this.filterChildren(child, value))
         .filter(vl => !!vl);
      return {
         groupName: suggestion.groupName,
         children: filteredChildren,
      };
   }

   private filterChildren(child: SelectableOption, value: string): SelectableOption {
      if (typeof(child.value) === 'string' && child.value.includes(value)) {
         return {
            value: child.value,
            label: '',
            disabled: child.disabled,
         };
      }
   }


   private getFieldLength(value: string, schemaItem: SchemaItem): number {
      let getCharLength = (text: string) => text.length;
      if (schemaItem.customLength) {
         getCharLength = eval(schemaItem.customLength); // eslint-disable-line no-eval
      }
      return getCharLength(value);
   }
}
