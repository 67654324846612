import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogModel {
   title: string;
   message: string;
   confirmBtnLabel: string;
   cancelBtnLabel?: string;
}

@Component({
   selector: 'bb-confirm-dialog',
   templateUrl: './confirm-dialog.component.html',
   styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
   title: string;
   message: string;
   confirmBtnLabel: string;
   cancelBtnLabel: string;

   constructor(
      public dialogRef: MatDialogRef<ConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
   ) {
      this.dialogRef.disableClose = true;

      this.title = data.title;
      this.message = data.message;
      this.confirmBtnLabel = data.confirmBtnLabel;
      this.cancelBtnLabel = data.cancelBtnLabel;
   }

   onConfirm(): void {
      this.dialogRef.close(true);
   }

   onDismiss(): void {
      this.dialogRef.close(false);
   }
}

