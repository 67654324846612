<div *ngIf="ready">
   <div *ngIf="dataSource.length; else nomanagersavailable">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z0">

         <ng-container matColumnDef="delete">
            <th mat-header-cell class="bb-action-icon" *matHeaderCellDef>
               <span class="deleted-icon">Delete</span>
            </th>
            <td mat-cell class="bb-action-icon" *matCellDef="let i = index">
               <button
                  mat-icon-button
                  class="btn-link user-remove"
                  (click)="markAsDeleted(i)"
                  [disabled]="disabledRow(i)"
                  [ngClass]="{'row-deleted': disabledRow(i), 'row-new': newRow(i) && !disabledRow(i)}"
                  >
                  <span class="material-icon"> cancel </span>
               </button>
            </td>
         </ng-container>

         <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> User name </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': disabledRow(i), 'row-new': newRow(i)}">
               <span *ngIf="disabledRow(i) || newRow(i); else anchor"> {{element.username}}</span>
               <ng-template #anchor><a [href]="userEditUrl(element.id)"> {{element.username}} </a></ng-template>
            </td>
         </ng-container>
         <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef> Full Name </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': disabledRow(i), 'row-new': newRow(i)}"> {{element.fullname}} </td>
         </ng-container>
         <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': disabledRow(i), 'row-new': newRow(i)}">
               <span *ngIf="disabledRow(i) || newRow(i); else anchor"> {{element.email}}</span>
               <ng-template #anchor><a [href]="emailString(element.email)" target="_top"> {{element.email}} </a></ng-template>
            </td>
         </ng-container>
         <ng-container matColumnDef="emailverified">
            <th mat-header-cell *matHeaderCellDef> Verified</th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{'row-deleted': disabledRow(i), 'row-new': newRow(i)}">
               <mat-checkbox
                  [ngModel]="verifiedStatus(i)"
                  [disabled]="disabledRow(i) || verifiedStatus(i)"
                  (change)="userVerifiedChanged($event.checked, i)"
                  >
               </mat-checkbox>
            </td>

         </ng-container>
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </mat-table>
      <section *ngIf="dataSource?.length" class="bb-api-paginator">
         <bb-api-paginator
            [loading]="!pageReady"
            [totalRowCount]="dataSource?.length ? dataSource.length: 0"
            [pageSize]="pagination[managerType]?.limit"
            (paginationChanged)="onPaginationChange($event)"
         ></bb-api-paginator>
      </section>
      <div class="save-user" *ngIf="saveEnabled()">
         <button mat-raised-button color="primary" type="submit" (click)="updateManagers()">
            Update Managers
         </button>
         <button mat-stroked-button color="primary" (click)="discardChanges()">
            Discard Changes
         </button>
      </div>
   </div>
   <ng-template #nomanagersavailable><span class="nomanagers">No managers defined</span></ng-template>
   <div class="addparent" *ngIf="canadd === 'True'">
      <div *ngIf="canSearchSiteUsers(); else cannotSearchSiteUsers">
         <span class="addchild"> Search user </span>
         <div class="addchild form-cell">
            <bb-autocomplete-item
               [inValue]=""
               [groupOptions]="filteredOptions$ | async"
               (inValueChange)="autocompleteFormChange($event)"
               (selectionChanged)="onAutocompleteSelectionChanged($event)"
               [resetAfterSelection]="true">
            </bb-autocomplete-item>
         </div>
      </div>
      <ng-template #cannotSearchSiteUsers>
         <span class="addchild"> Search user </span>
         <mat-form-field  class="addchild" appearance="outline">
            <input
               type="text"
               matInput
               required
               [errorStateMatcher]="matcher"
               [formControl]="addManagerFormControl"
            />
            <mat-error *ngIf="addManagerFormControl.invalid">
               {{ getErrorMessage() }}
            </mat-error>
         </mat-form-field>
         <button mat-raised-button color="primary" type="submit" (click)="addManager()" class="addchild" [disabled]="addManagerFormControl.invalid">
            Add {{roleName}}
         </button>
      </ng-template>

   </div>
</div>
