import DynamicFormModule from './dynamic-form';
import FiltersModule from './filters';

import UploadModule from './upload/upload.module';

import AccountsApiModule from './api/accounts/accounts-api.factory';
import AccountUsersApi from './api/account-users-api.factory';
import ArrivalsFolderEndpointAssignmentsApiModule from './api/arrivals-folder-endpoint-assignments-api.factory';
import ArrivalsFoldersModuleApiModule from './api/arrivals-folders-api.factory';
import CloudApiModule from './api/cloud-api.factory';
import ColumnsApiModule from './api/columns-api.factory';
import EdgeServersApiModule from './api/edge-servers-api.factory';
import EndpointsApiModule from './api/endpoints.api.factory';
import IngestQueueItemsApiModule from './api/ingest-queue-items-api.service';
import PublishApiModule from './api/publish-help/publish-help-api.service.ajs';
import ServicesApiModule from './api/services-api.factory';
import StatusApiModule from './api/status-api.factory';
import UsersApiModule from './api/users-api.factory';
import EditorDataApiModule from './api/editor-data-api.factory';

import ChoiceModalModule from './components/choice-modal';
import ComboDirectiveModule from './components/combo/combo.directive';
import FbdnEllipsisModule from './components/fbdn-ellipsis/fbdn-ellipsis.component';
import FbdnIconModule from './components/fbdn-icon/fbdn-icon.component.ajs';
import FbdnTabsModule from './components/fbdn-tabs';
import NotificationAreaModule from './components/notification-area/notification-area.module';
import PublishButtonDescriptionModule from './components/publish-button-description/publish-button-description.component';
import ShowServerNotificationModule from './components/show-server-notification/show-server-notification.component.ajs';
import AutoUpdatingImgModule  from './components/auto-updating-img/auto-updating-img.component.ajs';

import DialogModule from './directives/dialog/dialog.module';
import FbdnDraggableModule from './fbdn-draggable/fbdn-draggable.module';
import FbdnAccordionModule from './directives/fbdn-accordion/fbdn-accordion.module';
import FbdnContextmenuModule from './directives/fbdn-contextmenu';
import FbdnLeftEllipsisModule from './directives/fbdn-left-ellipsis/fbdn-left-ellipsis-set.directive';
import HeightStyleDirectivesModule from './directives/height-style-directive/height-style.directive';
import MenuDirectionModule from './directives/menu-direction';
import StopPropagationModule from './directives/stop-propagation/stop-propagation.directive.ajs';

import ServicesModule from './services/services.module';
import SkeletonCacheModule from './utils/skeleton-cache.factory';

import NavigationModule from './utils/navigation/navigation.module';
import PageFactoryModule from './utils/page.factory';
import SaveTransactionModule from './utils/save-transaction';
import UpdatesFactoryModule from './utils/updates.factory';
import UrlServiceModule from './utils/url.service';

import ClickToEditModule from './click-to-edit';
import FbdnPopNextToFactoryModule from './fbdn-pop-next-to/fbdn-pop-next-to.factory';
import PopupPromiseModule from './popup-promise/popup-promise.module';
import HideInvalidForNewModule from './hide-invalid-for-new';
import NgSubmitModule from './ng-submit';
import TplFactoryModule from './tpl.factory';

import HttpModule from './fbdn-angular-modules/http';
import InputModule from './fbdn-angular-modules/input';


export const SharedAjsModule = angular.module('shared', [
   DynamicFormModule.name,
   FiltersModule.name,

   UploadModule.name,

   /* APIs */
   AccountsApiModule.name,
   AccountUsersApi.name,
   ArrivalsFolderEndpointAssignmentsApiModule.name,
   ArrivalsFoldersModuleApiModule.name,
   CloudApiModule.name,
   EdgeServersApiModule.name,
   ColumnsApiModule.name,
   EndpointsApiModule.name,
   IngestQueueItemsApiModule.name,
   PublishApiModule.name,
   ServicesApiModule.name,
   StatusApiModule.name,
   UsersApiModule.name,
   EditorDataApiModule.name,
   SkeletonCacheModule.name,

   /* Components */
   ChoiceModalModule.name,
   ComboDirectiveModule.name,
   FbdnEllipsisModule.name,
   FbdnIconModule.name,
   FbdnTabsModule.name,
   NotificationAreaModule.name,
   PublishButtonDescriptionModule.name,
   ShowServerNotificationModule.name,
   AutoUpdatingImgModule.name,

   /* Directives */
   DialogModule.name,
   FbdnAccordionModule.name,
   FbdnContextmenuModule.name,
   FbdnLeftEllipsisModule.name,
   HeightStyleDirectivesModule.name,
   MenuDirectionModule.name,
   StopPropagationModule.name,

   /* Services */
   ServicesModule.name,

   /* Utils */
   NavigationModule.name,
   PageFactoryModule.name,
   SaveTransactionModule.name,
   UpdatesFactoryModule.name,
   UrlServiceModule.name,

   ClickToEditModule.name,
   FbdnDraggableModule.name,
   FbdnPopNextToFactoryModule.name,
   PopupPromiseModule.name,
   HideInvalidForNewModule.name,
   NgSubmitModule.name,
   TplFactoryModule.name,

   HttpModule.name,
   InputModule.name,
]);
